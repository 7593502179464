.call-to-action {
    @extend %vertical-space;
    background-color: $brand-secondary;
    text-align: center;
}

.call-to-action__title {
    @extend %heading;
    color: $white;
    margin-bottom: rem-calc(28px);

    @include media-breakpoint-up(lg) {
        margin-bottom: rem-calc(56px);
    }
}

.call-to-action__list {
    @extend %reset-list;
    @extend %row;
    justify-content: center;
    margin-bottom: rem-calc(26px);

    @include media-breakpoint-up(lg) {
        margin-bottom: rem-calc(52px);
    }
}

.call-to-action__item {
    @extend .global__column;
    @extend .global__column--md-4;
    margin-bottom: rem-calc(30px);
    margin-left: auto;
    margin-right: auto;
    max-width: rem-calc(425px);
    position: relative;
}

.call-to-action__link {
    display: block;
    transition: $transition-base;

    &:hover,
    &:focus {
        opacity: 0.8;
    }
}

.call-to-action__image {
    width: 100%;
}

.call-to-action__button {
    @extend .button;
    @extend .button--outline-white;
}

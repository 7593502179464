//scss-lint:disable PlaceholderInExtend
.account {
    padding-top: 1rem;
}

.account__signin {
    //scss-lint:disable NameFormat
    @extend .global__column;
}

.account__register {
    //scss-lint:disable NameFormat
    @extend .global__column;
}

.my-account__button {
    @extend .button--full;
    display: block;
    margin-bottom: 1rem;
}

.login {
    @extend %container;
    @extend %vertical-space;
}

.login__title {
    @extend %heading;
}

.navigation {
    @extend .col-lg-auto;
    @extend %reset-list;
    background-color: $brand-secondary;
    font-family: $font-din;
    font-size: rem-calc($font-size-sm);
    padding: ($spacer / 2) 0;
    position: relative;

    @include media-breakpoint-up(lg) {
        background-color: transparent;
        height: rem-calc(52px);
        padding: 0;
    }
}

.navigation--primary {
    @extend .global__column--lg-12;

    @include media-breakpoint-up(lg) {
        order: 2;
    }
}

.navigation--secondary {
    @include media-breakpoint-up(lg) {
        font-size: rem-calc($font-size-xs);
        order: 1;
        text-align: right;
    }
}

.navigation--tertiary {
    @include media-breakpoint-up(lg) {
        font-size: rem-calc($font-size-xs);
        order: 0;
    }
}

.navigation__container {
    @extend %container--lg;
    height: 100%;
    pointer-events: none;

    @include media-breakpoint-up(lg) {
        z-index: 2070;
    }
}

.navigation__tray {
    @extend %row--lg;
    -webkit-overflow-scrolling: touch;
    box-shadow: $popover-box-shadow;
    height: 100%;
    justify-content: flex-end;
    overflow-y: scroll;
    transform: translateY(-100%);
    z-index: 2000;

    @include media-breakpoint-up(lg) {
        //scss-lint:disable ImportantRule
        box-shadow: none;
        height: auto;
        overflow: visible;
        transform: none !important;
        transition: none !important;
    }

    .navigation-is-active & {
        transform: translateY(rem-calc($header-background-height));
    }
}

.navigation__item {
    display: block;
    max-height: rem-calc(40px);
    position: relative;
    transition: $transition-base;
    will-change: max-height;

    @include media-breakpoint-up(lg) {
        display: inline-block;
        max-height: none;
    }

    &.is-closing {
        max-height: rem-calc(40px) !important;
    }

    &.is-active {
        max-height: rem-calc(600px);
    }

    .dropdown {
        position: static;
    }

    .dropdown__toggle {
        display: block;

        @include media-breakpoint-up(lg) {
            display: inline;
        }
    }

    .dropdown__menu {
        @include media-breakpoint-down(md) {
            bottom: 0;
            float: none;
            left: auto;
            position: relative;
            right: 0;
            top: 0;
            width: auto;
        }

        @include media-breakpoint-up(md) {
            min-width: rem-calc(478px);
            right: 0;
        }
    }
}

.navigation__link {
    color: $white;
    display: block;
    font-weight: bold;
    margin: 0;
    padding: ($spacer-y / 2) 0;
    pointer-events: all;
    position: static;
    white-space: nowrap;

    @include media-breakpoint-up(lg) {
        display: inline-block;
        padding: rem-calc(15.5px) $spacer-x;

        &:hover,
        &:focus {
            background-color: transparent;
            color: darken($white, 15%);
        }
    }

    &:hover,
    &:focus {
        background-color: darken($brand-secondary, 5%);
        color: $white;
        text-decoration: none;
    }

    .navigation__children-list > .navigation__item > & {
        padding-left: $spacer-x;

        &:hover,
        &:focus {
            @include media-breakpoint-up(lg) {
                background-color: darken($brand-secondary, 10%);
            }
        }
    }

    .navigation--primary & {
        @include media-breakpoint-up(lg) {
            padding: rem-calc(26px) rem-calc(16px);
        }
    }

    .navigation--primary .navigation__children-list & {
        padding: rem-calc(15.5px) $spacer-x;
    }

    .dropdown__indicator & {
        margin: 0 0 0 rem-calc(5px);
    }
}

.navigation__link--parent {
    cursor: pointer;
    padding-bottom: 0;
    padding-top: 0;

    &:hover,
    &:focus {
        background-color: $brand-secondary;
        color: $white;
        text-decoration: none;

        @include media-breakpoint-up(lg) {
            background-color: transparent;
            cursor: initial;
        }
    }

    .navigation--primary & {
        &:hover,
        &:focus {
            @include media-breakpoint-up(lg) {
                background-color: $header-background;
            }
        }
    }

    .dropdown__toggle {
      padding-bottom: rem-calc(8px);
      padding-top: rem-calc(8px);
    }
}

.navigation__link__container {
    @extend %container;

    @include media-breakpoint-up(lg) {
        max-width: none;
        padding: 0;
        position: static;
        width: auto;
    }
}

.navigation__vector {
    margin-right: ($spacer / 3);
}

.navigation__title {
    display: inline-block;
    text-transform: uppercase;
    vertical-align: baseline;
}

.navigation__badge {
    @include badge-variant($brand-primary);
    border-radius: 50%;
    display: inline-block;
    height: rem-calc(21px);
    line-height: rem-calc(19px);
    margin-left: ($spacer-x / 3);
    padding: 0;
    text-align: center;
    vertical-align: top;
    width: rem-calc(21px);
}

.navigation__children-toggle {
    @extend .vector-chevron-down;
    color: $white;
    margin-left: ($spacer-x / 3);

    @include media-breakpoint-up(lg) {
        display: none;
    }

    .navigation__link:hover & {
        color: darken($white, 15%);
    }

    svg {
        fill: currentColor;
        transform: rotate(0deg);
        transition: $transition-base;

        //scss-lint:disable SelectorDepth
        .navigation__item.is-active > .navigation__link & {
            transform: rotate(180deg);
        }
    }
}

.navigation__children {
    display: none;
    z-index: -1;

    @include media-breakpoint-up(lg) {
        bottom: auto;
        box-shadow: 0 0 1px black;
        left: auto;
        position: absolute;
        right: 0;
        top: rem-calc($header-background-height);
        z-index: 3000;
    }

    .navigation__link:hover & {
        @include media-breakpoint-up(lg) {
            background-color: darken($brand-secondary, 5%);
            display: block;
        }
    }

    .navigation__item.is-active & {
        @include media-breakpoint-down(md) {
            display: block;
        }
    }

    .navigation--primary & {
        @include media-breakpoint-up(lg) {
            left: 0;
            right: auto;
            top: rem-calc(76px);
        }
    }
}

.navigation__children-list {
    @extend %reset-list;
    padding-top: ($spacer-y / 2);

    @include media-breakpoint-up(lg) {
        padding-top: 0;
    }
}

.navigation__prepended {
    color: $white;
    display: inline-block;
    margin-right: ($spacer-x / 3);
}

.domain-sale__primary {
    margin-bottom: 2rem;
    position: relative;

    @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
    }
}

.domain-sale__primary__background {
    @extend %position-as-parent;
    @extend .vector-world-map;
    color: $brand-primary;

    @include media-breakpoint-up(md) {
        top: rem-calc(74px);
    }
}

.domain-sale__title {
    @extend %heading;
    text-align: left;
}

.domain-sale__subtitle {
    font-size: rem-calc(22px);
    margin-bottom: rem-calc(50px);
    max-width: rem-calc(440px);

    em {
        color: $brand-success;
        font-style: normal;
        font-weight: $font-weight-medium;
    }
}

.domain-sale__price {
    color: $brand-primary;
    font-size: rem-calc(100px);
    font-weight: $font-weight-bold;
    line-height: 1;
    margin: 0 0 rem-calc(10px);
}

.domain-sale__caption {
    color: $brand-primary;
    font-size: rem-calc($font-size-xs);
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
}

.domain-sale__horizontal-rule {
    border-color: lighten($gray-lighter, 7%);
    border-style: solid;
    margin: rem-calc(32px) auto rem-calc(48px) 0;
    max-width: rem-calc(54px);
}

.domain-sale__search-button {
    @extend .button;
    @extend .button--outline-primary;
    padding-left: 2rem;
    padding-right: 2rem;

    @include media-breakpoint-up(md) {
        padding-left: 4rem;
        padding-right: 4rem;
    }
}

.kb {
    padding: 1rem 0;
}

.kb-article {
    h1, h2, h3, h4, h5, h6 {
        font-family: $font-whitney;
        font-weight: $font-weight-semibold;
    }
}

.kb-article__heading {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.kb-article__revision-date {
    font-size: $small-font-size;
}

.kb-listing__categories {
    justify-content: space-around;
}

.kb-listing__category-container {
    display: flex;
    flex: 0 0 25%;
    padding: $grid-gutter-width-base / 2;
    padding-top: 0;
}

.kb__filter--button {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    justify-content: center;
    min-height: 6.5rem;
    white-space: normal;
    width: 100%;
}

.kb-filter-vector {
    height: 3rem;
    margin: 0 auto;
    width: 3rem;
}

.kb-listing__articles {
    list-style: none;
    padding: 0;
}

.kb-listing__articles__article {
    border-bottom: 1px solid $gray-lighter;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}

.kb-listing__articles-link {
    color: $body-color;
}

.kb-listing__articles-heading {
    color: $brand-secondary;
    font-family: $font-whitney;
    font-size: 2rem;
    font-weight: $font-weight-semibold;
    line-height: (40 / 36);
    margin-bottom: 0.5rem;
}

.kb__aside__padding {
    margin-left: rem-calc(36px);
    margin-right: rem-calc(36px);
}

.kb__aside-link {
    color: $white;

    &:hover {
        color: $gray-lighter;
        text-decoration: underline;
    }
}

.kb__all-link {
    margin-bottom: 1rem;
}

.kb__aside__filter-heading {
    border-bottom: 1px solid rgba($white, 0.3);
    color: $brand-primary;
    display: inline-block;
    font-family: $font-liberator;
    font-size: rem-calc(18px);
    font-weight: normal;
    margin-left: rem-calc(36px);
    margin-right: rem-calc(36px);
    margin-bottom: 1rem;
    text-align: left;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(24px);
    }
}

.aside-block__list--kb {
    min-height: 0;
}

.kb__search-form {
    input {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }

    button {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
}

body {
    height: 100vh;
    overflow-x: hidden;
    position: relative;
    width: 100vw;
}

picture {
    display: block;
    height: 100%;
    width: 100%;
}

img {
    // enable responsive images by default
    @extend .img-fluid;
}

@font-face {
    font-family: 'DIN';
    src: url($assets-path + '/fonts/DIN BoldAlternate.eot');
    src: url($assets-path + '/fonts/DIN BoldAlternate.eot?#iefix') format('embedded-opentype'),
             url($assets-path + '/fonts/DIN BoldAlternate.woff') format('woff'),
             url($assets-path + '/fonts/DIN BoldAlternate.ttf')  format('truetype'),
             url($assets-path + '/fonts/DIN BoldAlternate.svg#baa81936f2538992dfe0ba3200e01ac1') format('svg');
    font-style: normal;
    font-weight: $font-weight-bold;
}

@font-face {
    font-family: 'Liberator';
    src: url($assets-path + '/fonts/Liberator-Medium.eot');
    src: url($assets-path + '/fonts/Liberator-Medium.eot?#iefix') format('embedded-opentype'),
             url($assets-path + '/fonts/Liberator-Medium.woff') format('woff'),
             url($assets-path + '/fonts/Liberator-Medium.ttf')  format('truetype'),
             url($assets-path + '/fonts/Liberator-Medium.svg#7aa7bf793c45b9d742ab914a1fa1c2c9') format('svg');
    font-style: normal;
    font-weight: $font-weight-normal;
}

@font-face {
    font-family: 'Whitney';
    src: url($assets-path + '/fonts/Whitney-Book-Pro.eot');
    src: url($assets-path + '/fonts/Whitney-Book-Pro.eot?#iefix') format('embedded-opentype'),
             url($assets-path + '/fonts/Whitney-Book-Pro.woff') format('woff'),
             url($assets-path + '/fonts/Whitney-Book-Pro.ttf')  format('truetype'),
             url($assets-path + '/fonts/Whitney-Book-Pro.svg#f28578544bcd221f22d5dc42685e92f2') format('svg');
    font-style: normal;
    font-weight: $font-weight-normal;
}

@font-face {
    font-family: 'Whitney';
    src: url($assets-path + '/fonts/Whitney-Medium-Pro.eot');
    src: url($assets-path + '/fonts/Whitney-Medium-Pro.eot?#iefix') format('embedded-opentype'),
             url($assets-path + '/fonts/Whitney-Medium-Pro.woff') format('woff'),
             url($assets-path + '/fonts/Whitney-Medium-Pro.ttf')  format('truetype'),
             url($assets-path + '/fonts/Whitney-Medium-Pro.svg#e970fe581655537ab12e96c670414c04') format('svg');
    font-style: normal;
    font-weight: $font-weight-medium;
}

@font-face {
    font-family: 'Whitney';
    src: url($assets-path + '/fonts/Whitney-Semibold-Pro.eot');
    src: url($assets-path + '/fonts/Whitney-Semibold-Pro.eot?#iefix') format('embedded-opentype'),
             url($assets-path + '/fonts/Whitney-Semibold-Pro.woff') format('woff'),
             url($assets-path + '/fonts/Whitney-Semibold-Pro.ttf')  format('truetype'),
             url($assets-path + '/fonts/Whitney-Semibold-Pro.svg#bed15bb33dfb9232e772bdb41a228cd5') format('svg');
    font-style: normal;
    font-weight: $font-weight-semibold;
}


.comparison-table {
    @extend %vertical-space;
    background-color: $gray-lightest;
}

.comparison-table__item__header {
    display: flex;
    flex-flow: column nowrap;

    @include media-breakpoint-up(lg) {
      // add space for potential title/caption wrapping in order to prevent
      // table rows from becoming misaligned
      min-height: rem-calc(120px);
    }
}

.comparison-table__container {
    @extend %container;
    position: relative;
}

.comparison-table__title {
    flex: 1 1 auto;
    margin-bottom: 1.5rem;

    @include media-breakpoint-down(lg) {
        text-align: center;
    }

    @include media-breakpoint-up(lg) {
        left: rem-calc(15px);
        padding-right: rem-calc(6px);
        position: absolute;
        text-align: left;
        top: rem-calc(4px);
        width: 25%;
    }
}

.comparison-table__list {
    @extend %reset-list;
    @extend %row;

    @include media-breakpoint-up(lg) {
        align-items: flex-start;
        left: 25%;
        margin-left: 0;
        margin-right: 0;
        position: relative;
        width: 75%;
    }
}

.comparison-table__item {
    @extend .global__column;
    @extend .global__column--sm-6;
    @extend .global__column--lg-3;
    margin-bottom: 2rem;

    &:hover{
        background-color: $white;
        box-shadow: 0 0 5px $submarine;
        z-index: 10;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
    }

    &.is-active {
        background-color: darken($gray-lightest, 5%);
    }

    &:nth-child(4n + 1) {
        color: $brand-primary;
    }

    &:nth-child(4n + 2) {
        color: $brink-pink;
    }

    &:nth-child(4n + 3) {
        color: $christi;
    }

    &:nth-child(4n + 4) {
        color: $mariner;
    }
}

.comparison-table__item--individual {
    color: $brand-primary;
}

.comparison-table__item--business {
    color: $brink-pink;
}

.comparison-table__item--corporate {
    color: $christi;
}

.comparison-table__item--enterprise {
    color: $mariner;
}

.comparison-table__item__title,
.comparison-table__caption,
.comparison-table__price-container {
    text-align: center;
}

.comparison-table__item__title {
    color: currentColor;
    font-family: $font-liberator;
    font-size: rem-calc(24px);
    margin-bottom: 0;
    text-transform: uppercase;
}

.comparison-table__caption {
    color: $body-color;
    flex: 0 1 auto;
}

.comparison-table__price {
    @include clearfix;
    color: $black;
    display: inline-table;
    line-height: 0;
    margin-bottom: 0.5rem;
    text-align: center;
    vertical-align: top;

    sup {
        display: table-cell;
        font-size: $font-size-lg;
        font-weight: bold;
        top: 0;
        vertical-align: top;

        &:first-of-type {
            font-size: rem-calc(28px);
        }
    }

    span {
        display: table-cell;
        line-height: 0;
        vertical-align: bottom;

        &:first-of-type {
            font-size: rem-calc(48px);
            font-weight: bold;
        }
    }
}

.comparison-table__price-per {
    color: $black;
    font-size: rem-calc($font-size-xs);
    text-transform: uppercase;
}

.comparison-table__button {
    @extend .button;
    @extend .button--info;
    margin-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;
    width: 100%;

    @include media-breakpoint-up(lg) {
        width: 70%;
    }

    &:hover,
    &:focus {
        background-color: darken($buttercup, 15%);
        border-color: darken($buttercup, 15%);
        color: $white;
    }
}

.comparison-table__button--red {
    @extend .button--danger;

    &:hover,
    &:focus {
        background-color: darken($brand-danger, 15%);
        border-color: darken($brand-danger, 15%);
        color: $white;
    }
}

.comparison-table__records {
    @extend %reset-list;
    color: $body-color;
}

.comparison-table__record {
    border-bottom: 1px solid $gray-lighter;
    padding-bottom: rem-calc(10px);
    padding-top: rem-calc(10px);
    position: relative;

    @include media-breakpoint-up(lg) {
        padding-bottom: rem-calc(28px);
        padding-top: rem-calc(28px);
    }
}

.comparison-table__record__title {
    font-weight: $font-weight-semibold;

    @include media-breakpoint-up(lg) {
        display: none;
    }

    .comparison-table__item:first-child & {
        padding-bottom: rem-calc(10px);
        padding-top: rem-calc(10px);

        @include media-breakpoint-up(lg) {
            border-bottom: 1px solid $gray-lighter;
            display: block;
            left: 0;
            padding-bottom: rem-calc(25px);
            padding-right: 1rem;
            padding-top: rem-calc(25px);
            position: absolute;
            text-align: left;
            top: rem-calc(2px);
            transform: translateY(rem-calc(4px)) translateX(-100%);
            width: 130%;
        }
    }
}

.comparison-table__record__value {
    float: right;

    @include media-breakpoint-up(lg) {
        float: none;
        text-align: center;
    }
}

.comparison-table__record__vector {
    @extend .vector-tick;
}

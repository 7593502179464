.pagination {
    @extend .pagination;
    align-self: center;
    border-radius: 0;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 rem-calc(-5px) 1rem;

    @include media-breakpoint-up(md) {
        justify-content: center;
        text-align: center;
    }

    &.pagination--next-prev {
        border-bottom: 1px solid $gray-lighter;
        border-top: 1px solid $gray-lighter;
        justify-content: space-between;
        margin-bottom: rem-calc(40px);
        max-width: none;
        padding-bottom: rem-calc(20px);
        padding-top: rem-calc(20px);
    }
}

.pagination--left {
    justify-content: flex-start;
}

.pagination > li,
.pagination__item {
    @extend .page-item;
    flex: 0 1 rem-calc(34px);
    margin: 0 rem-calc(5px) rem-calc(5px);
    text-align: center;

    @include media-breakpoint-up(md) {
        flex: 0 1 rem-calc(34px);
    }

    &:first-child,
    &:last-child {
        flex: 0 1 100%;

        @include media-breakpoint-up(md) {
            flex: 0 1 rem-calc(34px);
        }

        .pagination--next-prev & {
            flex: 0 1 auto;
            width: auto;
        }
    }

    &.active,
    &.is-active {
        @extend .active;
    }

    .pagination--next-prev & {
        text-align: left;
        width: 50%;

        &:last-child {
            text-align: right;
        }
    }
}

// Nest for bootstrap classes... yay
// Copied from bootstrap's pagination, just applied where we need it
.pagination > li.active > span {
    background-color: $pagination-active-bg;
    border-color: $pagination-active-border;
    color: $pagination-active-color;
    z-index: 2;
}

.pagination > li > a,
.pagination > li > span,
.pagination__link {
    @extend .page-link;
    @extend .button;
    @extend .button--sm;
    @extend .button--outline-primary;
    // scss-lint:disable ImportantRule
    border-radius: 0 !important;
    border: 1px solid;
    max-width: rem-calc(311px);
    padding: rem-calc(6px);
    width: 100%;

    @include hover-focus {
        background-color: $brand-primary;
        box-shadow: none;
    }

    @include media-breakpoint-up(sm) {
        height: rem-calc(34px);
        width: 100%;
    }

    .pagination--spaced & {
        padding: rem-calc(6px) rem-calc(12px);
    }

    .pagination--next-prev & {
        @extend .button--link;
        border: none;
        color: $body-color;
        font-size: rem-calc($font-size-base);
        font-weight: $font-weight-book;
        height: auto;
        text-transform: none;
        width: auto;
    }

    .pagination__item.disabled & {
        border-color: transparent;
    }
}

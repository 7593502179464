.checkout {
    @extend %vertical-space;
}

.checkout__fields {
    @extend .global__column;
    @extend .global__column--lg-7;
    @include media-breakpoint-up(lg) {
        order: -1;
    }
}

.checkout__reseller {
    padding-bottom: 0.5rem;
}

.checkout__details {
    padding-bottom: 0.5rem;

    .checkout__reseller + & {
        border-top: 1px dotted $brand-primary;
        padding-top: 1rem;
    }
}

.checkout__address,
.checkout__au-eligible {
    border-top: 1px dotted $brand-primary;
    padding-top: 1rem;
}

.checkout__au-eligible__optional {
    border: 1px dotted $brand-danger;
    margin-bottom: 1rem;
    padding: 0.5rem;
    padding-bottom: 0;
}

.checkout__payment-methods {
    display: none;
}

.hk-loading {
    display: none;
}

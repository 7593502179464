// hide CSS background-image when injecting svg
i {
    background-size: contain;
}

[svg-data-uri] {
    background-size: 0;
    max-height: 100%;
    max-width: 100%;
    vertical-align: top;

    svg {
        color: currentColor;
        height: 100%;
        vertical-align: top;
        width: 100%;
    }
}

[svg-data-uri]:not(.icon-text-blocks__vector) {
    path {
        fill: currentColor;
    }
}

.vector-user {
    height: rem-calc(16px);
    width: rem-calc(18px);
}

.vector-cart {
    height: rem-calc(16px);
    vertical-align: middle;
    width: rem-calc(16px);
}

.vector-chevron-down {
    height: rem-calc(9px);
    vertical-align: middle;
    width: rem-calc(14px);
}

.vector-speed {
    color: $gray-light;
    height: rem-calc(120px);
    width: rem-calc(120px);
}

.vector-mixer {
    color: $gray-light;
    height: rem-calc(124px);
    width: rem-calc(120px);
}

.vector-uptime {
    color: $gray-light;
    height: rem-calc(118px);
    width: rem-calc(117px);
}

.vector-australia {
    color: $gray-light;
    height: rem-calc(180px);
    width: rem-calc(161px);
}

.vector-plus {
    color: $brand-tertiary;
    height: rem-calc(24px);
    width: rem-calc(24px);
}

.vector-cpanel {
    height: rem-calc(52px);
    width: rem-calc(78px);
}

.vector-cloud {
    height: rem-calc(52px);
    width: rem-calc(84.4px);
}

.vector-cloud-environment {
    background-size: contain;
}

.vector-domains {
    height: rem-calc(52px);
    width: rem-calc(52px);
}

.vector-envelope {
    height: rem-calc(51px);
    width: rem-calc(72px);

    .icon-text-blocks & {
        height: rem-calc(19.7px);
        width: rem-calc(28px);
    }

    .cart-summary & {
        height: rem-calc(14px);
        width: rem-calc(19px);
    }
}

.vector-lock {
    height: rem-calc(52px);
    width: rem-calc(43.4px);
}

.vector-users {
    height: rem-calc(52px);
    width: rem-calc(71.26px);
}

.vector-facebook {
    height: rem-calc(24px);
    width: rem-calc(24px);
}

.vector-twitter {
    height: rem-calc(24px);
    width: rem-calc(24px);
}

.vector-linkedin {
    height: rem-calc(24px);
    width: rem-calc(24px);
}

.vector-hostking {
    height: rem-calc(22px);
    width: rem-calc(144px);
}

.vector-magnifying-glass {
    height: rem-calc(28px);
    width: rem-calc(28px);

    .icon-text-blocks & {
        height: rem-calc(28px);
        width: rem-calc(28px);
    }

    .blog__search & {
        height: rem-calc(18px);
        width: rem-calc(18px);
    }
}

.vector-arrow-up {
    height: rem-calc(28px);
    width: rem-calc(19px);

    .icon-text-blocks & {
        height: rem-calc(28px);
        width: rem-calc(19px);
    }
}

.vector-outlined-australia {
    height: rem-calc(49px);
    width: rem-calc(50px);

    path {
        fill: inherit;
        stroke: currentColor;
    }
}

.vector-outlined-one-click-install {
    height: rem-calc(50px);
    width: rem-calc(50px);
}

.vector-outlined-control-panel {
    height: rem-calc(46px);
    width: rem-calc(48px);
}

.vector-outlined-servers {
    height: rem-calc(47px);
    width: rem-calc(55px);
}

.vector-outlined-shield {
    height: rem-calc(50px);
    width: rem-calc(41px);
}

.vector-outlined-headset {
    height: rem-calc(50px);
    width: rem-calc(48px);
}

.vector-outlined-speed {
    height: rem-calc(51px);
    width: rem-calc(50px);
}

.vector-outlined-databases {
    height: rem-calc(50px);
    width: rem-calc(46px);
}

.vector-outlined-uptime {
    height: rem-calc(42px);
    width: rem-calc(42px);
}

.vector-close {
    height: rem-calc(14px);
    width: rem-calc(14px);
}

.vector-tick {
    height: rem-calc(16.92px);
    width: rem-calc(22px);
}

.vector-downtime {
    height: rem-calc(18px);
    width: rem-calc(14px);
}

.vector-error-fault-indicator-client {
    height: rem-calc(71px);
    width: rem-calc(91px);
}

.vector-error-fault-indicator-server {
    height: rem-calc(72px);
    width: rem-calc(116px);
}

.vector-error-fault-indicator-host {
    height: rem-calc(70px);
    width: rem-calc(81px);
}

.vector-error-fault-indicator-arrow {
    height: rem-calc(15px);
    width: rem-calc(107px);
}

.vector-world-map {
    height: rem-calc(389px);
    width: rem-calc(723px);
}

.vector-parked {
    height: rem-calc(84px);
    width: rem-calc(84px);
}

.vector-circle-cpanel {
    height: rem-calc(42px);
    width: rem-calc(42px);
}

.vector-circle-downtime {
    height: rem-calc(42px);
    width: rem-calc(42px);
}

.vector-circle-envelope {
    height: rem-calc(42px);
    width: rem-calc(42px);
}

.vector-circle-lock {
    height: rem-calc(42px);
    width: rem-calc(42px);
}

.vector-circle-tick {
    height: rem-calc(42px);
    width: rem-calc(42px);
}

.vector-circle-www {
    height: rem-calc(42px);
    width: rem-calc(42px);
}

.vector-globe {
    height: rem-calc(48px);
    width: rem-calc(49px);
}

.vector-servers {
    height: rem-calc(43px);
    width: rem-calc(48px);
}

.vector-conversation {
    height: rem-calc(48px);
    width: rem-calc(49px);
}

.vector-power {
    height: rem-calc(21px);
    vertical-align: middle;
    width: rem-calc(18px);
}

.vector-house {
    height: rem-calc(17px);
    width: rem-calc(18px);
}

.vector-list {
    height: rem-calc(18px);
    width: rem-calc(18px);
}

.vector-hard-disk {
    height: rem-calc(24px);
    width: rem-calc(25px);
}

.vector-line-chart {
    height: rem-calc(28px);
    width: rem-calc(32px);
}

.vector-renewals {
    height: rem-calc(32px);
    width: rem-calc(31px);
}

.vector-announcements {
    height: rem-calc(27px);
    width: rem-calc(30px);
}

.vector-hamburger__top {
    transition: $transition-base;

    .navigation-is-active & {
        transform: translateY(rem-calc(8px)) rotate(45deg);
        transform-origin: center;
        fill-opacity: 1;
    }
}

.vector-hamburger__middle {
    transition: $transition-base;

    .navigation-is-active & {
        // scss-lint:disable ImportantRule
        fill-opacity: 0 !important;
    }
}

.vector-hamburger__bottom {
    transform-origin: center;
    transition: $transition-base;

    .navigation-is-active & {
        transform: translateY(rem-calc(-8px)) rotate(-45deg);
        fill-opacity: 1;
    }
}

.vector-user-secret {
    height: 1.5rem;
    width: 1.5rem;
}

.hero {
    background-color: $brand-secondary;
    max-height: rem-calc(276px);
    overflow: hidden;
    position: relative;

    @include media-breakpoint-up(md) {
        max-height: rem-calc(360px);
    }

    @include media-breakpoint-up(xl) {
        max-height: rem-calc(460px);
    }

    // if directly after the the header
    .header + .main > & {
        margin-top: rem-calc($header-background-height);

        @include media-breakpoint-up(sm) {
            margin-top: 0;
        }
    }
}

.hero--large {
    max-height: rem-calc(390px);

    @include media-breakpoint-up(md) {
        max-height: rem-calc(530px);
    }

    @include media-breakpoint-up(xl) {
        max-height: rem-calc(660px);
    }
}

.hero__image {
    height: auto;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    width: 100vw;
}

.hero__overlay {
    @extend %position-as-parent;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    @include media-breakpoint-up(sm) {
        margin-top: rem-calc($header-background-height);
    }
}

.hero__title {
    @extend %heading--lg;
    color: $white;
    text-align: center;
}

.hero__subtitle {
    @extend %description;
    color: $brand-tertiary;
    font-weight: normal;
}

.hero__text {
    margin-bottom: $spacer-y;
}

.hero__text,
.hero__button {
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.hero__button {
    @extend .button;
    @extend .button--outline-white;
    display: block;

    @include media-breakpoint-up(sm) {
        display: inline-block;
    }
}

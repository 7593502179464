html {
    font-size: 100%;
}

body {
    @extend %font-smoothing-antialiased;
    font-size: rem-calc($font-size-base);
}

// 1. remove margin-top by default to simplify vertical spacing
h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 0; // 1

    &:empty {
        display: none;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $brand-secondary;
    font-family: $font-liberator;
    font-size: 2rem;
    font-weight: normal;
    line-height: (40 / 36);
    margin-bottom: 0.5rem;
}

strong,
b {
    font-weight: $font-weight-bold;
}

small {
    font-size: rem-calc($font-size-xs);
}

a {
    color: $link-color;
    text-decoration: $link-decoration;

    &:hover,
    &:focus {
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }
}

[href^="tel:"] {
    @include media-breakpoint-up(md) {
        color: currentColor;
        text-decoration: none;

        &:hover,
        &:focus {
            color: currentColor;
            text-decoration: none;
        }
    }
}

//
// Screenreaders
//
.sr-only {
    @include sr-only();
}

.sr-only-focusable {
    @include sr-only-focusable();
}

%truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

%heading {
    font-family: $font-liberator;
    font-size: rem-calc(28px);
    font-weight: normal;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(52px);
    }
}

%heading--sm {
    font-family: $font-liberator;
    font-size: rem-calc(24px);
    font-weight: normal;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(36px);
    }
}

%heading--lg {
    font-family: $font-liberator;
    font-size: rem-calc(36px);
    font-weight: normal;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(64px);
    }
}

%description {
    font-size: rem-calc($font-size-sm);
    margin-left: auto;
    margin-right: auto;
    max-width: rem-calc(700px);
    text-align: center;

    @include media-breakpoint-up(md) {
        font-size: rem-calc($font-size-base);
    }
}

%position-as-parent {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

%position-as-parent-sm {
    @include media-breakpoint-up(sm) {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}

%reset-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

%font-smoothing-antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

%font-smoothing-auto {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
}

%container {
    @include make-container;
    width: 100%;
    // force fluid containers until 'xl' breakpoint's max-width is met
    // otherwise use the following to restore all breakpoint max-widths
    // @include make-container-max-widths();
    @include media-breakpoint-up(xl) {
        max-width: rem-calc(map-get($container-max-widths, 'xl'));
    }
}

%container--md {
    width: 100%;

    @include media-breakpoint-up(md) {
        @include make-container;
    }

    @include media-breakpoint-up(xl) {
        max-width: rem-calc(map-get($container-max-widths, 'xl'));
    }
}

%container--lg {
    width: 100%;

    @include media-breakpoint-up(lg) {
        @include make-container;
    }

    @include media-breakpoint-up(xl) {
        max-width: rem-calc(map-get($container-max-widths, 'xl'));
    }
}

%row {
    @include make-row;
}

%row--md {
    @include media-breakpoint-up(md) {
        @include make-row;
    }
}

%row--lg {
    @include media-breakpoint-up(lg) {
        @include make-row;
    }
}

%row--unguttered {
    //scss-lint:disable ImportantRule
    @include make-row;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

%vertical-space {
    padding-bottom: 2rem;
    padding-top: 2rem;

    @include media-breakpoint-up(lg) {
        padding-bottom: 3rem;
        padding-top: 3rem;
    }
}

%vertical-space--xs {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;

    @include media-breakpoint-up(lg) {
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
}

%vertical-space--sm {
    padding-bottom: 1rem;
    padding-top: 1rem;

    @include media-breakpoint-up(lg) {
        padding-bottom: 2rem;
        padding-top: 2rem;
    }
}

%vertical-space--lg {
    padding-bottom: 3rem;
    padding-top: 3rem;

    @include media-breakpoint-up(lg) {
        padding-bottom: 5rem;
        padding-top: 5rem;
    }
}

%sr-only {
    @include sr-only();
}

.error {
    text-align: center;

    .global__container {
        max-width: rem-calc(682px);
    }
}

.error__title {
    font-family: $font-family-sans-serif;
    font-size: rem-calc(100px);
    font-weight: $font-weight-bold;
    line-height: 1;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(180px);
    }
}

.error__subtitle {
    margin: 0 0 1rem;
}

.error__description {
    font-size: rem-calc($font-size-sm);
    margin: 0 auto 1rem;
    max-width: rem-calc(290px);

    .error--502 & {
        max-width: rem-calc(474px);
    }
}

.error__block {
    background-color: $gray-lightest;
    padding: rem-calc(30px);
    text-align: left;

    p {
        font-size: rem-calc($font-size-sm);

        strong {
            font-size: rem-calc($font-size-base);
        }
    }
}

.error__fault-indication {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
    }

    .vector-error-fault-indicator-arrow {
        align-self: center;
        display: inline-block;
        flex: 0 0 auto;
        transform: rotate(90deg) translateX(-30%) translateY(-480%);

        @include media-breakpoint-up(md) {
            transform: rotate(0deg);
        }
    }
}

.error__fault__indicator {
    flex: 0 0 auto;
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
        margin: 2rem 1rem;
    }
}

.error__fault-labels {
    @extend %reset-list;
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.error__fault-label {
    flex: 1 1 auto;
    margin: 0.5rem 0;
    text-align: center;

    @include media-breakpoint-up(md) {
        margin: 1rem 0;
    }
}

.error__fault-label__title {
    font-weight: $font-weight-bold;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
        margin-bottom: rem-calc(10px);
    }
}

.error__fault-label__status {
    color: $brand-success;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
    }

    .error__fault-label--error & {
        color: $brand-danger;
    }
}

.error__message {
    color: $brand-danger;
}

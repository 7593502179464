.select-plan-options__addons {
    .form__custom-control {
        padding-bottom: rem-calc(15px);
        padding-top: rem-calc(15px);
    }

    .select-plan-options__vector {
        flex: 0 0 rem-calc(36px);
        left: rem-calc(42px);
    }

    .form__custom-control__description {
        padding-left: rem-calc(36px + $font-size-base);
        padding-right: 1rem;

        @include media-breakpoint-up(md) {
            padding-right: 2rem;
        }
    }
}

.select-plan-options__price {
    flex: 0 1 auto;
    float: right;
}

.select-plan-options__vector {
    @extend %position-as-parent;
    height: rem-calc(36px);
    top: 50%;
    transform: translateY(-50%);
    width: rem-calc(36px);
}

.select-plan-options__addons__title,
.select-plan-options__addons__subtitle {
    display: block;
}

.select-plan-options__addons__title {
    font-weight: $font-weight-bold;
}

.select-plan-options__addons__subtitle {
    display: none;

    @include media-breakpoint-up(sm) {
        display: block;
    }
}

.select-plan-options__chosen-domain,
.select-plan-options__other-domain {
    background-color: $gray-lightest;
    margin-bottom: 1rem;
    padding: 1rem;

    .form__custom-control__description {
        font-size: rem-calc($font-size-sm);
    }

    .form__input-group__button-container {
        margin-bottom: rem-calc(20px);
    }
}

.select-plan-options__chosen-domain__list,
.select-plan-options__other-domain__list {
    @extend %reset-list;
}

.select-plan-options__chosen-domain__list {
    border-top: 1px dashed $gray-light;
}

.select-plan-options__chosen-domain__item,
.select-plan-options__other-domain__item {
    align-items: center;
    border-bottom: 1px dashed $gray-light;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: rem-calc(17.5px);
    padding-top: rem-calc(17.5px);

    @include media-breakpoint-up(sm) {
        flex-wrap: nowrap;
    }

    &:last-of-type {
        border-bottom: none;
    }
}

.select-plan-options__chosen-domain__title,
.select-plan-options__other-domain__title,
.select-plan-options__chosen-domain__price,
.select-plan-options__other-domain__price,
.select-plan-options__chosen-domain__button-container,
.select-plan-options__other-domain__button-container {
    display: block;
}

.select-plan-options__chosen-domain__title,
.select-plan-options__other-domain__title {
    flex: 1 1 100%;
    margin-bottom: rem-calc(5px);
    position: relative;
    word-break: break-word;

    @include media-breakpoint-up(sm) {
        flex: 1 1 60%;
        margin-bottom: 0;
    }
}

.select-plan-options__chosen-domain__price,
.select-plan-options__other-domain__price {
    flex: 1 1 auto;
    font-size: rem-calc($font-size-sm);
    margin-right: 1rem;
    text-align: right;
}

.select-plan-options__chosen-domain__button-container,
.select-plan-options__other-domain__button-container {
    flex: 0 0 auto;
    text-align: right;
}

.select-plan-options__other-domain__label {
    font-weight: $font-weight-bold;
}

.select-plan-options__chosen-domain__button,
.select-plan-options__other-domain__button {
    @extend .button;
    @extend .button--primary;
    @extend .button--sm;
}

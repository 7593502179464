.modal {
    @extend .fade;
}

.modal__dialog {
    @extend .modal-dialog;

    @include media-breakpoint-down(sm) {
        margin: rem-calc(104px) rem-calc(15px);
        max-width: 100%;
    }

    .modal--small & {
        @extend .modal-sm;
    }

    .modal--large & {
        @extend .modal-lg;
    }
}

.modal__content {
    @extend .modal-content;
    border-radius: 0;
    color: $body-color;
    font-family: $font-family-sans-serif;
    font-size: rem-calc($font-size-sm);
    font-weight: $font-weight-book;
    padding: rem-calc(15px);
    text-align: left;
    white-space: normal;
}

.modal__body {
    @extend .modal-body;
    padding: rem-calc(10px) rem-calc(15px);
}

.modal__header {
    @extend .modal-header;
    padding: rem-calc(10px) rem-calc(15px);
}

.modal__title {
    @extend .modal-title;
    @extend %heading--sm;
    color: $brand-secondary;
    font-size: rem-calc(24px);
    margin-left: 0;
    text-align: left;
}

.modal__footer {
    @extend .modal-footer;

    .button--primary {
        color: $white !important;
    }
}

.modal__close {
    @extend .button;
    @extend .button--link;
    background: transparent;
    border: none;
    bottom: auto;
    color: $gray;
    left: auto;
    margin: 0;
    // increase size of close button hitbox
    padding: 1rem;
    position: absolute;
    right: rem-calc(30px);
    top: rem-calc(15px);
    // maintain position with increased hitbox size
    transform: translateX(1rem);

    @include hover-focus {
        box-shadow: none;
        color: $body-color;
    }
}

.modal__close__vector {
    @extend .vector-close;
    color: inherit;
    vertical-align: top;
}

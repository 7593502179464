.button {
    @extend %font-smoothing-antialiased;
    @include button-size($btn-padding-y, $btn-padding-x, $font-size-sm, $btn-border-radius);
    @include transition($btn-transition);
    background-color: $white;
    border: $input-btn-border-width solid $input-border-color;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    font-weight: $btn-font-weight;
    line-height: $btn-line-height;
    text-align: center;
    text-transform: uppercase;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    z-index: $zindex-button;

    @include hover-focus {
        box-shadow: none;
        text-decoration: none;
    }

    &:focus,
    &.button--focus {
        box-shadow: none;
        outline: 0;
    }

    // Disabled comes first so active can properly restyle
    &.button--disabled,
    &:disabled {
        @include box-shadow(none);
        cursor: $cursor-disabled;
        opacity: 0.65;
    }

    &:active,
    &.button--active {
        @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
        background-image: none;
    }
}

// Future-proof disabling of clicks on buttons
// scss-lint:disable QualifyingElement
.button--disabled,
fieldset[disabled] .button {
    pointer-events: none;
}

// Button modifiers
.button--primary {
    @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-bg);
}

.button--secondary {
    @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-bg);
}

.button--info {
    @include button-variant($btn-info-color, $btn-info-bg, $btn-info-bg);
}

.button--success {
    @include button-variant($btn-success-color, $btn-success-bg, $btn-success-bg);
}

.button--warning {
    @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-bg);
}

.button--danger {
    @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-bg);
}

.button--outline-white {
    @include button-outline-variant($white, $white);

    &:hover,
    &:focus,
    &:active {
        background-color: $black-transparent;
    }
}

.button--outline-primary {
    @include button-outline-variant($btn-primary-bg, $btn-primary-border);

    &:hover,
    &:focus,
    &:active {
        color: $white;
    }
}

.button--outline-secondary {
    @include button-outline-variant($btn-secondary-border, $btn-secondary-border);

    &:hover,
    &:active {
        color: $white;
    }
}

.button--outline-info {
    @include button-outline-variant($btn-info-bg, $btn-info-border);

    &:hover,
    &:active {
        color: $white;
    }
}

.button--outline-success {
    @include button-outline-variant($btn-success-bg, $btn-success-border);

    &:hover,
    &:active {
        color: $white;
    }
}

.button--outline-warning {
    @include button-outline-variant($btn-warning-bg, $btn-warning-border);

    &:hover,
    &:active {
        color: $white;
    }
}

.button--outline-danger {
    @include button-outline-variant($btn-danger-bg, $btn-danger-border);

    &:hover,
    &:active {
        color: $white;
    }
}

// Button Sizes
.button--lg {
    // line-height: ensure even-numbered height of button next to large input
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-border-radius-lg);
}

.button--sm {
    // line-height: ensure proper height of button next to small input
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-xs, $btn-border-radius-sm);
}

.button--xs {
    // line-height: ensure proper height of button next to small input
    @include button-size($btn-padding-y-sm / 2, $btn-padding-x-sm / 2, $font-size-xs, $btn-border-radius-sm);
}

.button--full {
    // scss-lint:disable ImportantRule
    width: 100% !important;
}

// Link buttons - Make a button look and behave like a link
.button--link {
    border-radius: 0;
    color: $link-color;
    font-weight: $font-weight-normal;
    padding: 0;

    @include hover {
        border-color: transparent;
    }

    @include hover-focus {
        background-color: transparent;
        color: $link-hover-color;
        text-decoration: $link-hover-decoration;
    }

    &,
    &:active,
    &.button--link--active,
    &:disabled {
        @include box-shadow(none);
        background-color: transparent;
    }

    &,
    &:focus,
    &:active {
        border-color: transparent;
    }

    &:disabled {
        color: $btn-link-disabled-color;

        @include hover-focus {
            text-decoration: none;
        }
    }
}

// Vertically space out multiple block buttons
.button__block {
    display: block;
    width: 100%;

    & + & {
        margin-top: $btn-block-spacing-y;
    }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
    &.button__block {
        width: 100%;
    }
}

.button__group {
    @extend .btn-group;
}

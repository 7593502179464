.dropdown {
    display: inline-block;

    .navigation__link & a {
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-normal;

        &.my-account__button {
            font-weight: $font-weight-semibold;
        }
    }

    .navigation__link & .navigation__title {
        font-family: $font-din;
    }

    .navigation__link--parent & {
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
}

.dropdown__toggle {
    @extend .dropdown-toggle;

    background: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    margin-bottom: 0.5rem;

    @include hover-focus {
        color: inherit;
    }

    &::after {
        // scss-lint:disable ImportantRule
        content: none !important;
        margin: 0;
    }

    .navigation__item & {
        margin-bottom: 0;
    }
}

.dropdown__indicator {
    @extend .vector-chevron-down;

    margin: 0;
    transition: transform 0.2s ease-in-out;

    .show & {
        transform: rotate(180deg);
    }
}

.dropdown__menu {
    @extend .dropdown-menu;

    left: auto;
    margin: 0;
    opacity: 1;
    padding: 2rem;
    transition: $transition-base;
    width: auto;

    .closing & {
        opacity: 0;
    }
}

.dropdown__item {
    @extend .dropdown-item;

    border-bottom: 1px solid $gray-lighter;
    padding: 0;

    &:last-of-type {
        border-bottom: none;
    }
}

.dropdown__link {
    color: currentColor;
    display: block;
    padding: rem-calc($dropdown-item-padding-x);

    &:hover {
        color: currentColor;
    }
}

.dropdown__divider {
    @extend .dropdown-divider;
}

.dropdown__main {
    box-sizing: border-box;

    .form {
        margin: 0;
    }
}

.dropdown__title {
    @extend %heading--sm;

    color: $brand-secondary;
    font-size: rem-calc(24px);
    text-align: left;
}

.dropdown-backdrop {
    .dropdown--prevent-auto-close & {
        pointer-events: none;
    }
}

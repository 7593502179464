// :root:root overrides all the things
:root:root {
    .ajax-message {
        font-size: 1rem;
        font-weight: bold;

        &.success {
            background: $brand-success;
        }

        &.warning {
            background: $brand-warning;
        }

        &.danger {
            background: $brand-danger;
        }

        a {
            color: $white;
            text-decoration: underline;

            //scss-lint:disable NestingDepth
            &:hover,
            &:focus,
            &:active {
                color: darken($white, 10%);
            }
        }
    }
}

.hidden-message {
    display: none;
}

.global {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    min-height: 100vh;
    position: relative;
}

.global__container {
    @extend %container;
}

.global__container--fixed {
    @include make-container;
    @include make-container-max-widths;
}


.global__container--vertical-space {
    @extend %vertical-space;
}

.global__container--vertical-space-sm {
    @extend %vertical-space--sm;
}

.global__container--vertical-space-lg {
    @extend %vertical-space--lg;
}

.global__container--double-padding {
    padding-left: rem-calc($grid-gutter-width-base);
    padding-right: rem-calc($grid-gutter-width-base);
}

.global__container-md {
    @extend %container--md;
}

.global__container-lg {
    @extend %container--lg;
}

.global__row {
    @include make-row;
}

.global__row--md {
    @extend %row--md;
}

.global__row--lg {
    @extend %row--lg;
}

.global__row--align-center {
    align-items: center;
}

.global__row--unguttered {
    @extend %row--unguttered;

    .global__column {
        padding-left: 0;
        padding-right: 0;
    }
}

.global__row--vertical-space {
    @extend %vertical-space;
}

.global__row--vertical-space-xs {
    @extend %vertical-space--xs;
}

.global__row--vertical-space-sm {
    @extend %vertical-space--sm;
}

.global__column {
    @include make-col-ready;
}

@for $i from 1 through 12 {
    .global__column--#{$i} {
        @include media-breakpoint-up(xs) {
            @include make-col($i);
        }
    }

    :root .global__column--sm-#{$i} {
        @include media-breakpoint-up(sm) {
            @include make-col($i);
        }
    }

    :root:root .global__column--md-#{$i} {
        @include media-breakpoint-up(md) {
            @include make-col($i);
        }
    }

    :root:root:root .global__column--lg-#{$i} {
        @include media-breakpoint-up(lg) {
            @include make-col($i);
        }
    }

    :root:root:root:root .global__column--xl-#{$i} {
        @include media-breakpoint-up(xl) {
            @include make-col($i);
        }
    }

    .global__column--push-#{$i} {
        @include media-breakpoint-up(xs) {
            @include make-col-push($i);
        }
    }

    :root .global__column--push-sm-#{$i} {
        @include media-breakpoint-up(sm) {
            @include make-col-push($i);
        }
    }

    :root:root .global__column--push-md-#{$i} {
        @include media-breakpoint-up(md) {
            @include make-col-push($i);
        }
    }

    :root:root:root .global__column--push-lg-#{$i} {
        @include media-breakpoint-up(lg) {
            @include make-col-push($i);
        }
    }

    :root:root:root:root .global__column--push-xl-#{$i} {
        @include media-breakpoint-up(xl) {
            @include make-col-push($i);
        }
    }

    .global__column--pull-#{$i} {
        @include media-breakpoint-up(xs) {
            @include make-col-pull($i);
        }
    }

    :root .global__column--pull-sm-#{$i} {
        @include media-breakpoint-up(sm) {
            @include make-col-pull($i);
        }
    }

    :root:root .global__column--pull-md-#{$i} {
        @include media-breakpoint-up(md) {
            @include make-col-pull($i);
        }
    }

    :root:root:root .global__column--pull-lg-#{$i} {
        @include media-breakpoint-up(lg) {
            @include make-col-pull($i);
        }
    }

    :root:root:root:root .global__column--pull-xl-#{$i} {
        @include media-breakpoint-up(xl) {
            @include make-col-pull($i);
        }
    }
}

.global__column--auto {
    flex: 0 0 auto;
    width: auto;
}

:root .global__column--sm-auto {
    @include media-breakpoint-up(sm) {
        flex: 0 0 auto;
        width: auto;
    }
}

:root:root .global__column--md-auto {
    @include media-breakpoint-up(md) {
        flex: 0 0 auto;
        width: auto;
    }
}

:root:root:root .global__column--lg-auto {
    @include media-breakpoint-up(lg) {
        flex: 0 0 auto;
        width: auto;
    }
}

:root:root:root:root .global__column--xl-auto {
    @include media-breakpoint-up(xl) {
        flex: 0 0 auto;
        width: auto;
    }
}

.global__section {
    margin-bottom: 1rem;
}

.global__md-section {
    margin-bottom: rem-calc(20px);
}

.global__inline-heading {
    display: inline-block;
    line-height: 1.34;
    margin: 0;

    span,
    p {
        display: inline;
    }
}

.global__header {
    flex: 0 0 auto;
    height: rem-calc($header-background-height);
    transition: $transition-base;

    @include media-breakpoint-up(md) {
        height: auto;
    }

    .navigation-is-active & {
        //scss-lint:disable ImportantRule
        height: auto !important;
    }

    .global--administration & {
        @extend %position-as-parent;
    }

    .global--administration &,
    .global--administration.navigation-is-active & {
        // scss-lint:disable ImportantRule
        height: rem-calc($admin-header-height) !important;
    }
}

.global__header--no-hang {
    height: rem-calc($header-background-height);

    @include media-breakpoint-up(lg) {
        height: rem-calc($header-full-height);
    }
}

.global__main {
    flex: 1 0 auto;
    position: relative;

    .global--administration & {
        height: 100%;
        transition: $transition-base;
        z-index: 1000;

        @include media-breakpoint-up(lg) {
            padding-left: rem-calc($aside-width);
            transform: none;
        }
    }


    .global--administration.navigation-is-active & {
        transform: translateX(rem-calc($aside-width));

        @include media-breakpoint-up(lg) {
            padding-left: rem-calc($aside-width);
            transform: none;
        }
    }
}

.global__footer {
    flex: 0 0 auto;
}

.global__inverse-background {
    background-color: $gray-lightest;
}

.global__success {
    color: $brand-success;
    font-weight: $font-weight-medium;
    word-break: break-word;
}

.global__info {
    color: $brand-info;
    font-weight: $font-weight-medium;
    word-break: break-word;
}

.global__danger {
    color: $brand-danger;
    font-weight: $font-weight-medium;
    word-break: break-word;
}

.global__administration {
    flex: 1 1 auto;
    padding-top: rem-calc($admin-header-height);
}

.global__loading-gif {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.hidden {
    display: none;
}

.cards {
    @extend %vertical-space--lg;
    background-color: $brand-secondary;
}

.cards__title {
    @extend %heading;
    color: $white;
    text-align: center;
}

.cards__description {
    @extend %description;
    color: $brand-tertiary;
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
        margin-bottom: 3rem;
    }
}

.cards__list {
    @extend %reset-list;
    @extend %row--unguttered;

    @include media-breakpoint-up(md) {
        //scss-lint:disable ImportantRule
        margin-left: auto !important;
        margin-right: auto !important;
        max-width: rem-calc(map-get($container-max-widths, md));
    }

    @include media-breakpoint-up(xl) {
        max-width: rem-calc(map-get($container-max-widths, xl));
    }
}

.cards__item {
    @extend .global__column;
    @extend .global__column--xl-3;
    @extend .global__column--sm-6;
    margin-bottom: rem-calc($grid-gutter-width-base);
    text-align: center;

    &:hover {
        transform: scale(1.05);
        transition: all .5s;
        z-index: 10;
    }

    &:nth-child(4n + 1) {
        color: $brand-primary;
    }

    &:nth-child(4n + 2) {
        color: $brink-pink;
    }

    &:nth-child(4n + 3) {
        color: $christi;
    }

    &:nth-child(4n + 4) {
        color: $mariner;
    }
}

.cards__item__container {
    background-color: $white;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1rem;

    // allow children to fit via growing
    .cards__title,
    .cards__caption,
    .cards__price,
    .cards__details {
        flex: 1 1 100%;
    }

    // allow cards__button to fit via shrinking
    > .cards__button {
        flex: 0 1 auto;
    }
}

.cards__item__title {
    color: currentColor;
    font-family: $font-liberator;
    font-size: rem-calc(24px);
    line-height: (20 / 24);
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    text-transform: uppercase;
}

.cards__caption {
    color: $body-color;
    font-size: rem-calc($font-size-sm);
    margin-bottom: 1.5rem;
}

.cards__price-container {
    flex: 1 1 100%;
}

.cards__price {
    @include clearfix;
    color: $black;
    display: inline-table;
    line-height: 0;
    margin-bottom: 0.5rem;
    text-align: center;
    vertical-align: top;

    sup {
        display: table-cell;
        font-size: rem-calc($font-size-lg);
        font-weight: bold;
        top: 0;
        vertical-align: top;

        &:first-of-type {
            font-size: rem-calc(28px);
        }
    }

    span {
        display: table-cell;
        line-height: 0;
        vertical-align: bottom;

        &:first-of-type {
            font-size: rem-calc(48px);
            font-weight: bold;
        }
    }
}

.cards__price-per {
    color: $black;
    font-size: rem-calc($font-size-xs);
    text-transform: uppercase;
}

.cards__details {
    @extend %reset-list;
    border-top: 1px solid $gray-lighter;
    color: $body-color;
    font-size: rem-calc(16px);
    margin-bottom: 1rem;
    padding-top: 1rem;
}

.cards__detail {
    margin-bottom: 0.25rem;

    &:last-child {
        margin-bottom: 0;
    }
}

.cards__button {
    @extend .button;
    @extend .button--info;
    width: 100%;

    &:hover,
    &:focus {
        color: $white;
    }
}

.cards__button--red {
    @extend .button;
    @extend .button--danger;
    width: 100%;

    &:hover,
    &:focus {
        color: $white;
    }
}

.cards__compare {
    color: $brand-tertiary;
    display: block;
    font-size: rem-calc($font-size-lg);
    text-align: center;

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(28px);
    }

    &:hover,
    &:focus {
        color: darken($brand-tertiary, 15%);
        text-decoration: none;
    }
}

.cards__compare__label {
    border-bottom: 1px dashed currentColor;
    color: currentColor;
}

.cards__compare__vector {
    @extend .vector-plus;
    color: currentColor;
    vertical-align: text-top;
}

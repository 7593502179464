.cloud-hosting-estimate {
    @extend %vertical-space--lg;
    background-color: $brand-primary;
    color: $white;
}

.cloud-hosting-estimate__text,
.cloud-hosting-estimate__vector-container {
    @extend .global__column;
}

.cloud-hosting-estimate__text {
    @extend .global__column--lg-6;
    order: 1;
    text-align: center;

    @include media-breakpoint-up(lg) {
        text-align: left;
    }

    a:not(.cloud-hosting-estimate__button) {
        color: $brand-secondary;
        text-decoration: none;

        &:hover,
        &:focus {
            color: $brand-secondary;
            text-decoration: underline;
        }
    }
}

.cloud-hosting-estimate__vector-container {
    @extend .global__column--lg-6;
    order: 0;
    position: relative;

    &::before {
        content: '';
        display: block;
        padding-top: (303 / 709) * 100%;
        width: 100%;
    }
}

.cloud-hosting-estimate__title {
    @extend %heading;
    margin-top: 2rem;
    text-align: center;

    @include media-breakpoint-up(lg) {
        margin-top: 0;
        text-align: left;
    }
}

.cloud-hosting-estimate__description {
    margin-bottom: 1.5rem;
    text-align: center;

    @include media-breakpoint-up(lg) {
        text-align: left;
    }
}

.cloud-hosting-estimate__button {
    @extend .button;
    @extend .button--secondary;
    display: block;
    margin-bottom: 0.25rem;
    margin-right: 1rem;
    text-align: center;

    @include media-breakpoint-up(md) {
        display: inline-block;
        margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
        display: block;
        margin-bottom: 0.25rem;
        text-align: center;
    }

    @include media-breakpoint-up(xl) {
        display: inline-block;
        margin-bottom: 0;
    }
}

.cloud-hosting-estimate__vector {
    @extend %position-as-parent;
    @extend .vector-cloud-environment;
    max-width: 100%;
}

.addresses {
    @extend %vertical-space;
}

.addresses__locations {
    @extend %reset-list;
    @extend %row;
}

.addresses__location {
    @extend .global__column;
    @extend .global__column--sm-4;
}

.addresses__location__title,
.addresses__detail__title {
    font-size: rem-calc(20px);
    font-weight: $font-weight-semibold;
    margin-bottom: 0.5rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
    }
}

.addresses__location__phone {
    font-size: rem-calc($font-size-sm);
    margin-bottom: 0.75rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 1.5rem;
    }
}

.addresses__location__address {
    font-size: rem-calc($font-size-sm);
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 2rem;
    }
}

.addresses__detail {
    @extend %row;
    margin-bottom: 0.5rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 1rem;
    }
}

.addresses__detail__title {
    @extend .global__column;
    @extend .global__column--4;
}

.addresses__detail__content {
    @extend .global__column;
    @extend .global__column--8;
    font-size: rem-calc($font-size-sm);
}

.addresses__detail__item {
    margin: 0;
}

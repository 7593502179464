.domain-listing {
    @extend %vertical-space;
    display: none;

    .cart__summary {
        margin-top: 0;
    }
}

.domain-listing__listing {
    @extend .global__column;
    @extend .global__column--lg-7;

    p {
        font-size: rem-calc($font-size-sm);
        font-weight: $font-weight-semibold;
        margin-bottom: 0.5rem;

        &:first-child {
            font-size: rem-calc($font-size-base);
        }

        &:last-child {
            font-weight: normal;

            @include media-breakpoint-down(md) {
                margin-bottom: 2rem;
            }
        }
    }
}

.domain-listing__results {
    @extend %reset-list;
    margin-bottom: 2rem;
    margin-top: 1rem;

    + & {
        margin-top: 2rem;
    }
}

.domain-listing__result {
    align-items: flex-start;
    border-bottom: 1px dashed $gray-light;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 1rem;
    padding-top: 1rem;

    @include media-breakpoint-up(md) {
        align-items: center;
        flex-wrap: nowrap;
        text-align: left;
    }
}

.domain-listing__result__title {
    flex: 1 1 100%;
    font-size: rem-calc($font-size-sm);
    margin-bottom: 0.5rem;
    word-break: break-word;

    @include media-breakpoint-up(sm) {
        flex: 1 1 60%;
        font-size: rem-calc($font-size-base);
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
        max-width: 45%;
        min-width: 45%;
        width: 45%;
    }

    strong {
        font-weight: bold;
    }
}

.domain-listing__result__status {
    color: $brand-success;
    flex: 1 1 50%;
    font-size: rem-calc($font-size-sm);
    margin-bottom: 0.5rem;
    text-align: left;
    text-transform: uppercase;

    @include media-breakpoint-up(sm) {
        flex: 1 1 20%;
        margin-bottom: 0;
    }
}

.domain-listing__result__status--unavailable,
.domain-listing__result__status--backorder {
    color: $brand-primary;
}

.domain-listing__result__status--error,
.domain-listing__result__status--unsupported {
    color: $brand-danger;
}

.domain-listing__result__price {
    flex: 1 1 50%;
    font-size: rem-calc($font-size-sm);
    margin-bottom: 0.5rem;
    text-align: right;

    @include media-breakpoint-up(sm) {
        flex: 1 1 20%;
    }

    @include media-breakpoint-up(md) {
        flex: 1 1 auto;
        margin-bottom: 0;
    }
}

.domain-listing__result__button-container {
    flex: 0 1 100%;
    padding-left: 0;

    @include media-breakpoint-up(md) {
        flex: 0 1 auto;
        padding-left: 2rem;
    }
}

.domain-listing__result__button {
    @extend .button;
    @extend .button--primary;
    @extend .button--sm;
    float: right;
    width: 40%;

    @include media-breakpoint-up(md) {
        width: auto;
    }

    &:hover,
    &:focus {
        color: $white;
    }
}

.domain-listing__result__button--disabled {
    @include box-shadow(none);
    cursor: $cursor-disabled;
    opacity: 0.65;
    pointer-events: none;
}

.domain-listing__title {
    @extend %heading--sm;
    color: $brand-secondary;
    font-size: rem-calc(24px);
    text-align: left;
}

.domain-listing__button {
    @extend .button;
    @extend .button--primary;
    margin-bottom: 2rem;

    @include media-breakpoint-down(sm) {
        width: 100%;
    }
}

.domain-listing__additional {
    display: none;
}

.services {
    @extend %vertical-space;
    color: $white;
    overflow: hidden;
    position: relative;
}

.services__background {
    @extend %position-as-parent;
    background-color: $brand-secondary;
    z-index: -1;
}

.services__background__image {
    height: auto;
    max-width: none;
    min-height: 100%;
    min-width: 100%;
    width: auto;
}

.services__primary,
.services__secondary {
    @extend .global__column;
    @extend .global__column--lg-5;
}

.services__secondary {
    @extend .global__column--push-lg-2;
}

.services__title {
    @extend %heading;
    color: $white;
    text-align: center;

    @include media-breakpoint-up(lg) {
        text-align: left;
    }
}

.services__description {
    font-size: rem-calc($font-size-sm);
    text-align: center;

    @include media-breakpoint-up(md) {
        font-size: rem-calc($font-size-base);
    }

    @include media-breakpoint-up(lg) {
        text-align: left;
    }
}

.services__button {
    @extend .button;
    @extend .button--outline-white;

    font-size: rem-calc($font-size-xs);
    left: 50%;
    margin-bottom: 0.5rem;
    position: relative;
    transform: translateX(-50%);

    @include media-breakpoint-up(md) {
        font-size: rem-calc($font-size-sm);
    }

    @include media-breakpoint-up(lg) {
        left: 0;
        margin-bottom: 0;
        transform: none;
    }
}

.services__list {
    @extend %reset-list;
    @extend %row;
    align-items: center;
    justify-content: center;
}

.services__item {
    @extend .global__column--2;
    @extend .global__column--lg-4;
    height: rem-calc(70px);
    position: relative;
    text-align: center;
    width: rem-calc(70px);

    @include media-breakpoint-up(md) {
        height: rem-calc(100px);
        width: rem-calc(100px);
    }

    @include media-breakpoint-up(lg) {
        border-right: 2px solid $white-transparent;
        border-top: 2px solid $white-transparent;
        height: rem-calc(162px);
        width: rem-calc(162px);
    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
        border-top: none;
    }

    &:nth-child(3) {
        border-right: none;
    }

    &:last-child {
        border-right: none;
    }
}

.services__item__vector,
.services__item__title {
    .services__item__link:hover &,
    .services__item__link:focus & {
        transform: scale(0.75);

        @include media-breakpoint-up(md) {
            transform: scale(1.25);
        }
    }
}

.services__item__vector {
    align-self: center;
    color: $white;
    flex: 0 1 auto;
    margin-bottom: 0.5rem;
    transform: scale(0.5);
    transform-origin: 50% 100%;
    transition: $transition-base;

    @include media-breakpoint-up(md) {
        transform: scale(1);
    }
}

.services__item__link {
    align-self: center;
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    &:hover,
    &:focus {
        color: $white-transparent;
        text-decoration: none;
    }
}

.services__item__title {
    align-self: center;
    color: $white;
    display: block;
    flex: 0 1 auto;
    font-weight: $font-weight-semibold;
    line-height: (20 / 18);
    margin: 0;
    text-decoration: none;
    transform: scale(0.7);
    transform-origin: 50% 0;
    transition: $transition-base;

    @include media-breakpoint-up(md) {
        transform: scale(1);
    }
}

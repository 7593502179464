//scss-lint:disable PlaceholderInExtend
.cart {
    @include clearfix;
}

.cart__title {
    @extend %heading--sm;
    color: $brand-secondary;
}

.cart__label {
    color: $boulder;
    display: none;
    font-size: rem-calc($font-size-xs);
    font-weight: $font-weight-medium;
    margin: 0;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
        display: block;
        margin-bottom: rem-calc(19.5px);
    }
}

.cart__header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.cart__label--main {
    @include media-breakpoint-up(md) {
        flex: 0 0 50%;
    }
}

.cart__label--terms {
    @include media-breakpoint-up(md) {
        flex: 0 0 30.5%;
    }
}

.cart__label--price {
    @include media-breakpoint-up(md) {
        flex: 0 0 19.5%;
        text-align: right;
    }
}

.cart__list {
    @extend %reset-list;
}

.cart__item {
    border-bottom: 1px solid $gray-lighter;
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    @include media-breakpoint-up(md) {
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: 0;
        padding-bottom: rem-calc(35.5px);
        padding-top: rem-calc(35.5px);
    }

    &:first-child {
        @include media-breakpoint-up(md) {
            border-top: 1px solid $gray-lighter;
        }
    }

    .form__custom-select {
        font-size: rem-calc($font-size-xs);
        padding-bottom: rem-calc(8px);
        padding-top: rem-calc(8px);

        @include media-breakpoint-up(md) {
            padding-right: 4rem;
            width: auto;
        }
    }
}

.cart__item__terms,
.cart__item__main {
    margin-bottom: 1rem;

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }
}

.cart__item__main {
    //scss-lint:disable NameFormat
    @extend .global__column--md-6;
    position: relative;
}

.cart__item__terms {
    //scss-lint:disable NameFormat
    @extend .global__column--md-4;
}

.cart__item__price {
    //scss-lint:disable NameFormat
    @extend .global__column--md-2;
    @include clearfix;
    font-size: rem-calc($font-size-sm);

    @include media-breakpoint-up(md) {
        text-align: right;
    }
}

.cart__item__price__amount {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 0.5rem;
    text-align: inherit;
}

.cart__item__vector {
    @extend %position-as-parent;
}

.cart__item__title {
    font-size: rem-calc($font-size-sm);
    font-weight: $font-weight-semibold;
    margin-bottom: 0;
    margin-left: rem-calc(42px + 16px);
    overflow-x: hidden;
}

.cart__item__subtitle {
    color: $boulder;
    font-size: rem-calc($font-size-xs);
    font-weight: $font-weight-medium;
    margin-bottom: 0;
    margin-left: rem-calc(42px + 16px);
    overflow-x: hidden;
}

.cart__item__remove-link {
    @extend .button;
    @extend .button--xs;
    @extend .button--outline-danger;
    float: right;

    @include media-breakpoint-up(md) {
        border: none;
        color: $gray-lighter;
        display: inline-block;
        width: auto;
    }

    @include hover-focus {
        @include media-breakpoint-up(md) {
            background-color: transparent;
            color: $gray;
        }
    }

    &:focus {
        @include media-breakpoint-up(md) {
            box-shadow: none;
        }
    }
}

.cart__item__remove {
    @extend .vector-close;
    height: rem-calc(10px);
    margin-right: 0.5rem;
    transition: $transition-base;
    vertical-align: middle;
    width: rem-calc(10px);

    @include media-breakpoint-up(md) {
        margin-right: 0;
    }

    &:hover {
        @include media-breakpoint-up(md) {
            transform: scale(1.5);
        }
    }
}

.cart__item__remove__label {
    vertical-align: middle;

    @include media-breakpoint-up(md) {
        display: none;
    }
}

.cart__totals {
    font-size: rem-calc(16px);

    @include media-breakpoint-up(md) {
        margin-left: auto;
        max-width: rem-calc(350px);
    }

    @include media-breakpoint-up(lg) {
        max-width: rem-calc(565px);
    }

    @include media-breakpoint-up(xl) {
        max-width: rem-calc(650px);
    }

    dl {
        @include clearfix;
        border-bottom: 1px solid $gray-lighter;
        font-weight: $font-weight-normal;
        margin: 0;
        padding-bottom: rem-calc(20px);
        padding-top: rem-calc(7px);

        &:first-of-type {
            margin-top: rem-calc(24px);
        }
    }

    dt {
        float: left;
        font-weight: inherit;

        &::after {
            content: ':';
            display: inline;
        }
    }

    dd {
        float: right;
        font-weight: inherit;
    }

    .cart__totals__grand-total {
        border-bottom: none;
        font-size: rem-calc(20px);
        font-weight: $font-weight-bold;
        margin-bottom: 1rem;
    }
}

.cart__button {
    @extend .button;
    @extend .button--info;
    width: 100%;

    @include media-breakpoint-up(sm) {
        float: right;
    }
}

.cart__summary {
    //scss-lint:disable NameFormat
    @extend .global__column;
    @extend .global__column--lg-5;

    @include media-breakpoint-up(lg) {
        margin-top: rem-calc(45px);
    }
}

@media screen and (max-width: 1191px) {
    .cart__summary {
      order: -1;
    }
}

.cart__empty {
    @extend %description;
}
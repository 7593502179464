.header {
    @extend %position-as-parent-sm;
    bottom: auto;
    height: 100%;
    pointer-events: none;
    z-index: 2000;

    .navigation-is-active & {
        @include media-breakpoint-up(md) {
            //scss-lint:disable ImportantRule
            height: auto !important;
        }
    }


    .global__container {
        pointer-events: all;
        position: relative;
    }

    .global__header--no-hang & {
        background-color: $brand-secondary;
        height: rem-calc($header-background-height);

        @include media-breakpoint-up(lg) {
            height: rem-calc($header-full-height);
        }
    }

    .navigation-is-active .global__header--no-hang & {
        height: auto;
    }
}

.header__background {
    @extend %position-as-parent;
    background-color: $brand-secondary;
    bottom: auto;
    height: rem-calc($header-background-height);
    z-index: 2060;

    @include media-breakpoint-up(md) {
        background-color: $header-background;
    }

    .navigation-is-active & {
        @include media-breakpoint-down(md) {
            background-color: $brand-secondary;
        }
    }
}

.header__logo {
    bottom: auto;
    height: auto;
    left: $spacer-x;
    margin-bottom: rem-calc(23px);
    //scss-lint:disable ImportantRule
    max-width: rem-calc($header-logo-width) !important;
    pointer-events: all;
    position: absolute;
    right: auto;
    top: rem-calc($header-logo-top);
    transition: $transition-base;
    width: 100%;
    z-index: 2070;
}


.header__toggle {
    bottom: auto;
    left: auto;
    position: absolute;
    right: $spacer-x;
    top: rem-calc(($header-background-height - $navigation-toggle-button-size) / 2);
    z-index: 2070;

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.header__toggle__button {
    display: block;
    height: rem-calc($navigation-toggle-button-size);
    transition: $transition-base;
    width: rem-calc($navigation-toggle-button-size);

    &:hover,
    &:focus {
        span {
            background-color: $navigation-toggle-colour--hover;
        }
    }
}

.header__toggle__title {
    @extend %sr-only;
}

.header__toggle__icon {
    @include clearfix;
    display: block;
    margin: 0;
    position: relative;
    transform: rotate(0deg);
    transition: $transition-base;

    span {
        background-color: $navigation-toggle-colour;
        border-radius: $border-radius;
        display: block;
        height: rem-calc($navigation-toggle-stripe-height);
        left: 0;
        opacity: 1;
        position: absolute;
        transform: rotate(0deg);
        transition: $transition-base;
        width: 100%;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2) {
            top: rem-calc($navigation-toggle-second-stripe-top);
        }

        &:nth-child(3) {
            top: rem-calc($navigation-toggle-third-stripe-top);
        }
    }

    .navigation-is-active & {
        span {
            //scss-lint:disable NestingDepth
            &:nth-child(1) {
                top: rem-calc($navigation-toggle-second-stripe-top);
                transform: rotate(45deg);
            }

            &:nth-child(2) {
                opacity: 0;
                width: 0%;
            }

            &:nth-child(3) {
                top: rem-calc($navigation-toggle-second-stripe-top);
                transform: rotate(-45deg);
            }
        }
    }
}

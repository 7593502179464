.accordion__title {
    @extend %heading--sm;
    border-bottom: 1px solid $gray-lighter;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    text-align: left;
}

.accordion__list {
    @extend %reset-list;
}

.accordion__item {
    margin-bottom: 1rem;
    position: relative;

    @include media-breakpoint-up(lg) {
        max-height: rem-calc(37px);
        overflow: hidden;
    }

    &.is-active {
        @include media-breakpoint-up(lg) {
            max-height: rem-calc(600px);
        }
    }
}

.accordion__link {
    display: block;
}

.accordion__item__title {
    background-color: $gray-lightest;
    color: $body-color;
    font-weight: $font-weight-semibold;
    margin-bottom: 0;
    padding-bottom: rem-calc(5px);
    padding-left: rem-calc(30px);
    padding-top: rem-calc(5px);
    position: relative;
    z-index: 100;
}

.accordion__item__vector {
    @extend .vector-chevron-down;
    color: $brand-primary;
    height: rem-calc(9.6px);
    left: 0;
    margin-right: 1rem;
    margin-top: rem-calc(14px);
    position: absolute;
    top: 0;
    width: rem-calc(16px);

    svg {
        transition: $transition-base;
    }

    .accordion__item.is-active & {
        svg {
            transform: rotate(180deg);
        }
    }
}

.accordion__item__content {
    display: none;
    margin-left: rem-calc(16px + $font-size-base);
    position: relative;
    z-index: 50;

    @include media-breakpoint-up(lg) {
        display: block;
        transform: translateY(-100%);
    }

    .accordion__item.is-active & {
        display: block;

        @include media-breakpoint-up(lg) {
            transform: translateY(0%);
        }
    }

    *:last-child {
        margin-bottom: 0;
    }
}

.progress {
    border-radius: 0;
}

.progress__bar {
    @extend .progress-bar;

    .hosting-overview & {
        height: rem-calc(10px);
    }

    .progress--danger & {
        background-color: $brand-danger;
    }

    .progress--info & {
        background-color: $brand-info;
    }

    .progress--success & {
        background-color: $brand-success;
    }
}

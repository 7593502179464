.icon-text-blocks {
    @extend %vertical-space;
}

.icon-text-blocks--condensed {
    padding-bottom: 0;
    padding-top: 0;
}

.icon-text-blocks--container-unpadd {
    > .global__container {
        padding-left: 0;
        padding-right: 0;
    }
}

.icon-text-blocks--fill {
    .icon-text-blocks__vector {
        path {
            fill: currentColor;
        }
    }
}

.icon-text-blocks__heading {
    @extend %heading;
    color: $brand-secondary;
    text-align: center;

    .icon-text-blocks--condensed & {
        @extend %heading--sm;
        text-align: left;
    }
}

.icon-text-blocks__description {
    @extend %description;
    flex: 1 1 100%;
    margin-bottom: 2.5rem;

    @include media-breakpoint-up(lg) {
        margin-bottom: 5rem;
    }

    .icon-text-blocks--condensed & {
        margin-bottom: 1rem;
    }
}

.icon-text-blocks__item__description {
    @extend %description;
    font-size: rem-calc($font-size-sm);

    .icon-text-blocks--condensed & {
        //scss-lint:disable ImportantRule
        flex: 1 1 100% !important;
        text-align: left;
    }
}

.icon-text-blocks__list {
    @extend %reset-list;
    @extend .global__row;
    justify-content: center;
}

.icon-text-blocks__item {
    @extend .global__column;
    @extend .global__column--sm-6;
    @extend .global__column--lg-3;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 1rem;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }

    .icon-text-blocks--condensed & {
        @extend .global__column--sm-4;
        @extend .global__column--lg-4;
        align-items: flex-end;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 2rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }
}

.icon-text-blocks__vector-container,
.icon-text-blocks__item__description,
.icon-text-blocks__button {
    flex: 1;
}

.icon-text-blocks__title {
    flex: 0;
    font-size: rem-calc($font-size-lg);
    font-weight: bold;
    line-height: (24 / 20);
    text-align: center;

    .icon-text-blocks--condensed & {
        align-self: center;
        flex: 1 1 auto;
        margin-bottom: 0.5rem;
        text-align: left;
    }
}

.icon-text-blocks__vector-container {
    margin: 0 auto 0.5rem;

    @include media-breakpoint-up(md) {
        margin: 0 auto 2rem;
    }

    .icon-text-blocks--condensed & {
        align-self: center;
        flex: 0 0 auto;
        height: auto;
        margin-bottom: 0.5rem;
        padding-right: 0.5rem;
        width: auto;

        @include media-breakpoint-up(lg) {
            flex: 1 1 100%;
            min-height: rem-calc(43px);
        }
    }
}

.icon-text-blocks__vector {
    display: block;
    height: rem-calc(160px);
    margin: auto;

    .icon-text-blocks--condensed & {
        color: $brand-primary;
        display: inline-block;
        margin: 0;
        vertical-align: middle;
    }
}

.icon-text-blocks__button {
    @extend .button;
    @extend .button--outline-primary;

    .icon-text-blocks--condensed & {
        flex: 0 0 100%;
    }
}

.icon-text-blocks__domains,
.icon-text-blocks__web-hosting {
    .icon-text-blocks__vector {
        height: 7rem;
        width: 7rem;
    }

    .icon-text-blocks__button {
        flex: none;
    }
}

.icon-text-blocks__domains {
    circle,
    g {
        fill: $silver-chalice;
    }
}

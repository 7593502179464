//scss-lint:disable ImportantRule
.domain-finder {
    @extend %vertical-space--sm;
    background-color: $brand-secondary;

    .form__input-group {
        display: flex;
        font-size: rem-calc($font-size-sm);

        @include media-breakpoint-up(md) {
            display: inline-flex;
            width: auto;
        }

        @include media-breakpoint-up(lg) {
            font-size: rem-calc($font-size-base);
            width: rem-calc(654px);
        }
    }

    .form__control {
        @include media-breakpoint-up(md) {
            min-width: rem-calc(275px);
        }
    }
}

.domain-finder__container {
    @extend %container;
    text-align: center;
}

.domain-finder__button-container {
    display: block;
    margin-top: 0.5rem;
    text-align: right;

    @include media-breakpoint-up(md) {
        display: inline-flex;
        margin-left: 0.5rem;
        margin-top: 0;
    }
}

.domain-finder__button {
    @extend .button;
    @extend .button--primary;
    padding-left: rem-calc(26px);
    padding-right: rem-calc(26px);
    width: 100%;

    @include media-breakpoint-up(md) {
        width: auto;
    }
}

.domain-finder__details {
    @extend %reset-list;
    margin-top: 1rem;
}

.domain-finder__detail {
    color: $brand-tertiary;
    display: block;
    font-size: rem-calc($font-size-xs);
    margin-bottom: 0.25rem;

    @include media-breakpoint-up(sm) {
        display: inline-block;
        font-size: rem-calc($font-size-sm);
        margin-bottom: 0;
        margin-right: 1rem;
    }

    @include media-breakpoint-up(md) {
        font-size: rem-calc($font-size-base);
    }

    &:last-child {
        margin-right: 0;

        @include media-breakpoint-up(sm) {
            margin-bottom: 0;
        }
    }
}

.domain-finder__validation-hints {
    color: $white;
    font-size: rem-calc($font-size-xs);
    margin-bottom: 0;
    margin-top: rem-calc(5px);
}

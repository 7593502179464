.form {
    margin-bottom: 2rem;

    p {
        font-size: rem-calc($font-size-sm);
    }

    button {
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: auto;
        }
    }
}

.form--checkout {
    margin-bottom: 0;
}

.form--enews-subscription {
    font-size: rem-calc($font-size-xs);

    @include media-breakpoint-up(md) {
        font-size: rem-calc($font-size-sm);
    }

    @include media-breakpoint-up(lg) {
        font-size: rem-calc($font-size-base);
    }

    .button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.form--help {
    h2,
    .lead {
        border-bottom: 1px solid $gray-lighter;
    }

    h2 {
        font-size: rem-calc(36px);
        margin-bottom: 1rem;
        padding-bottom: 1rem;
    }

    p:not(.lead) {
        font-size: rem-calc($font-size-base);
    }

    .lead {
        margin-bottom: 1.5rem;
        padding-bottom: 1.5rem;
        padding-top: 0.5rem;
    }
}

.form--blog-search {
    .button--link {
        padding: 0 rem-calc(15px);
        width: auto;
    }

    .form__control {
        height: rem-calc(48px);
    }
}

.form__title {
    font-size: rem-calc(22px);
    line-height: (26 / 22);
}

.form__dropdown-toggle {
    @extend .dropdown-toggle;
}

.form__dropdown-menu {
    @extend .dropdown-menu;
    @extend .dropdown-menu-right;
}

.form__dropdown-menu-right {
    @extend .dropdown-menu-right;
}

.form__dropdown-item {
    @extend .dropdown-item;
}

.form__button__vector {
    .form--blog-search & {
        @extend .vector-magnifying-glass;
        color: $white;

        .form__button:hover &,
        .form__button:focus & {
            color: $white;
        }
    }
}

label {
    font-size: rem-calc($font-size-sm);
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
    width: 100%;
}

.form--inline {
    @extend .form-inline;
}

.form__group {
    @extend .form-group;
}

.form__group--hidden {
    display: none;
}

.form__group--inline {
    @extend .form-inline;
}

button,
input,
optgroup,
select,
textarea {
    @extend %font-smoothing-antialiased;
    font-family: $font-family-sans-serif;
}

.form__control {
    @extend .form-control;
    box-shadow: none;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-book;

    @include hover-focus {
        box-shadow: none;
    }

    &:hover {
        border-color: $gray-light;
    }

    &:focus {
        background-color: $white;
        border-color: $gray-light;
    }
}

.form__control--sm {
    @extend .form-control-sm;
}

.form__control--lg {
    @extend .form-control-lg;
}

.form__control--file {
    @extend .form-control-file;
    padding-bottom: rem-calc(11.5px);
    padding-top: rem-calc(11.5px);
}

.form__check {
    @extend .form-check;
}

.form__check__label {
    @extend .form-check-label;
}

.form__check__input {
    @extend .form-check-input;
}

.form__text {
    @extend .form-text;
}

.form__input-group__container {
    display: block;

    @include media-breakpoint-up(md) {
        display: flex;
    }

    .form__input-group {
        @include media-breakpoint-up(md) {
            flex: 1 1 auto;
        }
    }

    .form__input-group__button-container {
        align-self: flex-start;
        vertical-align: top;

        @include media-breakpoint-up(md) {
            flex: 0 1 auto;
        }
    }
}

.form__input-group {
    @extend .input-group;
    align-self: flex-start;
    vertical-align: top;

    .form__control,
    .form__custom-select {
        padding-bottom: rem-calc(13.5px);
        padding-top: rem-calc(13.5px);
    }

    .form__control {
        border-right-width: 0;
    }

    .form__custom-select {
        border-left: 1px solid inherit;
        border-radius: 0 $border-radius $border-radius 0;
        padding-right: rem-calc(40px);
        width: auto;
    }

    .form--blog-search & {
        border-radius: $border-radius;
        border: 1px solid $input-border-color;

        .form__control {
            border-right-width: 0;
        }
    }

    .modal & {
        .form__control {
            border-radius: $input-border-radius;
            border-right-width: 1px;
        }
    }
}

.form__input-group__button-container {
    display: block;
    margin-top: 0.5rem;
    text-align: right;

    @include media-breakpoint-up(md) {
        display: inline-flex;
        margin-left: 0.5rem;
        margin-top: 0;
    }
}

.form__input-group__button {
    @extend .input-group-btn;

    .button {
        height: 100%;
    }

    .modal & {
        margin-left: 0.5rem;
    }
}

.form__input-group__addon {
    @extend .input-group-addon;
    padding-bottom: rem-calc(11.5px);
    padding-top: rem-calc(11.5px);
}

.form__label {
    display: block;
    font-size: rem-calc($font-size-base);
    text-transform: none;
}

.form__label--inline {
    @extend .col-form-label;
    margin-right: 1rem;
}

.form__custom-select {
    @extend .custom-select;
    height: auto;
    width: 100%;

    &:hover {
        border-color: $gray-light;
    }

    &:focus {
        background-color: $white;
        border-color: $gray-light;
    }
}

.form__custom-file {
    @extend .custom-file;

    &:hover {
        border-color: $gray-light;
    }

    &:focus {
        background-color: $white;
        border-color: $gray-light;
    }
}

.form__custom-file__input {
    @extend .custom-file-input;
}

.form__custom-file__control {
    @extend .custom-file-control;

    &::before {
        content: 'Browse';
    }

    &::after {
        content: 'Add files...';
    }
}

.form__inline {
    margin-bottom: 0.5rem;

    .form__custom-control {
        display: block;
        margin: 0;
        padding-left: rem-calc(22px);
        padding-right: rem-calc(13px);
        width: auto;

        @include media-breakpoint-up(sm) {
            display: inline-block;
        }
    }
}

.form__stacked {
    @extend .custom-controls-stacked;
    background-color: $gray-lightest;
    margin-bottom: 2rem;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.form__custom-control {
    @extend .custom-control;
    align-items: center;
    display: flex;
    font-size: rem-calc($font-size-base);
    font-weight: $font-weight-book;
    justify-content: space-between;
    margin-right: 0;
    padding-bottom: rem-calc(4px);
    padding-left: rem-calc(13px + 16px);
    padding-right: rem-calc(13px);
    padding-top: rem-calc(4px);
    text-transform: none;
    width: 100%;

    .form__stacked & {
        padding-left: rem-calc(13px + 16px + 13px);

        &:hover,
        &:focus {
            background-color: $twilight-blue;
        }
    }

    em {
        color: $brand-primary;
        font-style: normal;
        font-weight: $font-weight-bold;
    }

    .form__custom-control__indicator {
        border: 1px solid $input-border-color;
        top: 50%;
        transform: translateY(-50%);

        .form__stacked & {
            left: rem-calc(13px);
        }
    }
}

.form__custom-control--checkbox {
    @extend .custom-checkbox;
}

.form__custom-control--radio {
    @extend .custom-radio;
    margin-bottom: 0;
}

.form__custom-control__input {
    @extend .custom-control-input;

    &:checked + .form__custom-control__indicator {
        border-color: $brand-primary;
    }
}

.form__custom-control__indicator {
    @extend .custom-control-indicator;

    &::after {
        @extend %position-as-parent;
        border-radius: inherit;
        border: 2px solid $white;
        content: '';
        height: 100%;
        width: 100%;
    }

    &.form__error {
        border-color: $brand-danger;
    }
}

.form__custom-control__description {
    @extend .custom-control-description;
    flex: 1 1 auto;
    margin: 0;
}

.form__error {
    border-color: $brand-danger;
}

.g-recaptcha {
    transform-origin: left top;
    transform: scale(0.77);

    @include media-breakpoint-up(sm) {
        transform: initial;
    }
}

.cloud-calculator__primary {
    @extend %vertical-space;

    p {
        font-size: rem-calc($font-size-sm);
    }
}

.cloud-calculator__secondary {
    @extend %vertical-space;
    background-color: $gray-lightest;
}

.cloud-calculator__secondary__column {
    @extend .global__column;
    @extend .global__column--lg-6;
}

.cloud-calculator__resources {
    @extend .global__column;
    @extend .global__column--lg-7;

    @include media-breakpoint-up(md) {
        margin-left: auto;
        margin-right: auto;
        max-width: 58.3333333333%;
    }
}

.cloud-calculator__order-summary {
    @extend .global__column;
    @extend .global__column--lg-5;

    @include media-breakpoint-up(md) {
        margin-left: auto;
        margin-right: auto;
        max-width: 58.3333333333%;
    }
}

.cloud-calculator__price-label {
    display: none;
    float: right;
    //scss-lint:disable ImportantRule
    font-size: rem-calc($font-size-base) !important;
    font-weight: bold;
    margin-bottom: 0;

    @include media-breakpoint-up(lg) {
        display: block;
    }
}

.cloud-calculator__technologies {
    margin-top: rem-calc(52px);
}

.cloud-calculator__technologies__list {
    @extend %reset-list;
}

.cloud-calculator__technology {
    font-size: rem-calc($font-size-base);
    margin-bottom: 1rem;
}

.cloud-calculator__technology__title {
    font-size: rem-calc(24px);
    line-height: (20 / 24);
    margin-bottom: rem-calc(13px);
}

.cloud-calculator__technology__choice__title {
    font-weight: normal;

    strong {
        color: $brand-primary;
        font-weight: bold;
    }

    small {
        color: $brand-danger;
    }
}

.cloud-calculator__price {
    font-weight: normal;
    position: relative;
    z-index: 100;

    @include media-breakpoint-up(lg) {
        float: right;
    }

    &::before {
        content: ' - ';

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
}

.cloud-calculator__technology__choice__amount {
    font-weight: bold;
    text-transform: none;
}

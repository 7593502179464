.blog {
    @include media-breakpoint-up(lg) {
        width: (11.5 / 12) * 100%;
    }
}

.blog__primary {
    @extend .global__column;
    @extend .global__column--lg-9;
    order: 1;

    @include media-breakpoint-up(lg) {
        order: 0;
    }
}

.blog__secondary {
    @extend .global__column;
    @extend .global__column--lg-3;
    margin-bottom: 2rem;
    order: 0;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        order: 1;
    }
}

.blog__link {
    color: currentColor;
}

.blog__title {
    font-family: $font-whitney;
    font-size: rem-calc(32px);
    font-weight: $font-weight-semibold;
    line-height: (39 / 32);
}

.blog__byline {
    color: $gray;
    font-size: rem-calc($font-size-sm);
}

.blog__description {
    margin-bottom: 0;
}

.blog__listing {
    @extend %reset-list;
    @extend .global__row;
}

.blog__item {
    @extend .global__column;
    @extend .global__column--sm-6;
    @extend .global__column--lg-12;
    margin-bottom: rem-calc(45px);
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(lg) {
        border-bottom: 1px solid $gray-lighter;
        padding-bottom: rem-calc(45px);
    }
}

.blog__item__row {
    @extend .global__row;
    justify-content: center;
}

.blog__item__primary {
    @include make-col-ready;
    max-width: rem-calc(311px);

    @include media-breakpoint-up(lg) {
        flex: 0 1 auto;
        padding-left: 0;
        width: auto;
    }
}

.blog__item__secondary {
    @include make-col-ready;

    @include media-breakpoint-up(lg) {
        flex: 1 1 50%;
        width: auto;
    }
}

.blog__aside__title {
    font-weight: bold;
    text-transform: uppercase;

    .blog__socials & {
        @extend .global__column;
        @extend .global__column--auto;
        margin: 0;
    }
}

.blog__search {
    margin-bottom: 2rem;
}

.blog__categories {
    margin-bottom: 2rem;
}

.blog__categories__list {
    @extend .global__row;
    @extend .global__row--unguttered;
    color: $gray-lighter;
    justify-content: flex-start;
    list-style-type: none;
    padding-left: 0;

    @include media-breakpoint-up(lg) {
        display: block;
        list-style-type: square;
        margin: 0;
        padding-left: 1rem;
    }
}

.blog__category {
    @extend .global__column;
    @extend .global__column--auto;
    flex: 0 1 auto;
    font-size: rem-calc($font-size-sm);

    @include media-breakpoint-up(lg) {
        background: transparent;
        border: none;
        display: list-item;
        flex: none;
        margin: 0 0 1rem;
        padding: 0;
        text-align: left;
    }

    &:hover,
    &:focus,
    &:active {
        background: transparent;
        color: $link-hover-color;
    }
}

.blog__category__link {
    @extend .button;
    @extend .button--xs;
    @extend .button--outline-secondary;
    margin: 0 rem-calc(4px) rem-calc(4px) 0;

    @include media-breakpoint-up(lg) {
        border: none;
        color: $body-color;
        font-size: rem-calc(16px);
        font-weight: $font-weight-book;
        padding: 0;
        text-transform: none;

        &:hover,
        &:focus {
            background: transparent;
            color: $link-hover-color;
        }
    }
}

.blog__socials {
    @extend %reset-list;
    @extend .global__row;
    align-items: center;
}

.blog__socials__list {
    @extend %reset-list;
    @extend .global__column;
    @extend .global__column--auto;
}

.blog__social {
    display: inline-block;
    margin-right: rem-calc(15px);

    &:last-child {
        margin-right: 0;
    }
}

.blog__social__vector {
    transition: $transition-base;

    &:hover {
        transform: translateY(-20%);
    }
}

.blog__social__vector--facebook {
    @extend .vector-branded-facebook;
    height: rem-calc(32px);
    width: rem-calc(32px);
}

.blog__social__vector--twitter {
    @extend .vector-branded-twitter;
    height: rem-calc(32px);
    width: rem-calc(32px);
}

.blog__social__vector--linkedin {
    @extend .vector-branded-linkedin;
    height: rem-calc(32px);
    width: rem-calc(32px);
}

.blog__image {
    margin-bottom: rem-calc(35px);
}

.blog__post {
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $font-family-sans-serif;
        font-size: rem-calc(22px);
        font-weight: $font-weight-semibold;
        margin-top: rem-calc(27px);
        text-transform: normal;

        &:first-child {
            margin-top: 0;
        }
    }

    p {
        margin-bottom: rem-calc(27px);
    }
}

.blog__author__image-container {
    background-color: lighten($gray-lightest, 5%);
    border: 1px solid lighten($gray-lighter, 10%);
    display: block;
    margin: 0 auto 1rem;
    max-width: rem-calc(155px);
    padding: rem-calc(13px);

    @include media-breakpoint-up(sm) {
        display: inline-block;
    }
}

.blog__author__image {
    display: block;
    margin: 0 auto rem-calc(18px);
}

.blog__author__name {
    color: $boulder;
    font-size: rem-calc(16px);
    font-weight: $font-weight-semibold;
    margin-bottom: 0;
    text-align: center;
}

.blog__author__label {
    font-weight: bold;
    margin-bottom: rem-calc(8px);
}

.blog__sharing {
    margin-bottom: 2rem;
}

.parked-domain {
    text-align: center;

    .global__container {
        max-width: rem-calc(740px);
    }
}

.parked-domain__header {
    margin-bottom: 1rem;
    position: relative;
}

.parked-domain__header__background {
    @extend .vector-world-map;
    color: $brand-primary;

    svg {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        width: auto;
    }
}

.parked-domain__header__foreground {
    @extend %position-as-parent;
    top: 50%;
    transform: translateY(-50%);
}

.parked-domain__title {
    @extend %heading--sm;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-book;
    margin: 0 7rem;
    text-transform: none;
}

.parked-domain__vector {
    @extend .vector-parked;
    margin-bottom: rem-calc(25px);
}

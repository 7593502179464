.partners {
    @extend %vertical-space--sm;
    background-color: $gray-lightest;
}

.partners__title {
    color: $gray;
    font-family: $font-liberator;
    font-size: rem-calc(24px);
    font-weight: 500;
    margin-bottom: 0;
    text-align: center;

    @include media-breakpoint-up(lg) {
        margin-bottom: 1rem;
    }
}

.partners__list {
    @extend %reset-list;
    @extend %row--unguttered;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.partners__item {
    @extend .global__column--6;
    @extend .global__column--md-4;
    @extend .global__column--xl-auto;
    flex-grow: 1;

    @include media-breakpoint-up(xl) {
        margin: 1.5rem;
    }
}

.partners__link {
    display: block;
    height: 100%;
    padding: 1rem 0;
    width: 100%;

    &:hover,
    &:focus {
        opacity: 0.75;
    }
}

.partners__logo {
    max-width: 100%;
}

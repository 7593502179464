.aside-block {
    background-color: $brand-secondary;
    border-radius: rem-calc(8px);
    color: $white;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
        float: right;
        // push by half a column
        width: 95.833333333%;
    }
}

.aside-block__header {
    border-bottom: 1px solid rgba($white, 0.3);
    margin-bottom: 1.5rem;
    margin-left: rem-calc(36px);
    margin-right: rem-calc(36px);
    padding-top: rem-calc(36px);
}

.aside-block__main {
    margin-bottom: rem-calc(36px);
    margin-left: rem-calc(36px);
    margin-right: rem-calc(36px);

    .form__control {
        font-size: rem-calc($font-size-base);
    }
}

.aside-block__title {
    @extend %heading--sm;
    color: $brand-primary;
    display: inline-block;
    text-align: left;
}

.aside-block__link {
    color: $white;
    display: inline-block;
    padding: 0.25rem;

    &:hover,
    &:focus {
        color: rgba($white, 0.7);
    }
}

.aside-block__vector-container {
    display: inline-block;
    float: right;
}

.aside-block__vector {
    @extend .vector-cart;
    height: rem-calc(18px);
    width: rem-calc(18px);

    @include media-breakpoint-up(md) {
        height: rem-calc(28px);
        vertical-align: middle;
        width: rem-calc(28px);
    }
}

.aside-block__badge {
    @include badge-variant($brand-primary);
    border-radius: 50%;
    color: $white;
    display: inline-block;
    font-family: $font-din;
    font-size: rem-calc($font-size-xs);
    font-weight: normal;
    height: rem-calc(21px);
    line-height: rem-calc(19px);
    margin-left: ($spacer-x / 3);
    padding: 0;
    text-align: center;
    vertical-align: top;
    width: rem-calc(21px);

    .aside-block__link:hover &,
    .aside-block__link:focus & {
        background-color: rgba($brand-primary, 0.7);
    }
}

.aside-block__list {
    @extend %reset-list;
    margin-bottom: 1.5rem;
    min-height: rem-calc(100px);
    padding-left: rem-calc(36px);
    padding-right: rem-calc(36px);
}

.aside-block__item {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1rem;
    word-break: break-word;
}

.aside-block__item__title {
    flex: 1 1 100%;
    font-weight: $font-weight-semibold;
}

.aside-block__item__subtitle {
    color: $brand-tertiary;
    flex: 1 1 75%;
    font-size: rem-calc($font-size-sm);
}

.aside-block__item__price {
    color: $brand-tertiary;
    flex: 1 1 25%;
    font-size: rem-calc($font-size-sm);
    text-align: right;
}

.aside-block__item__text,
.aside-block__close-link {
    flex: 0 1 auto;
}

.aside-block__item__text {
    margin-right: 1rem;
}

.aside-block__close-link {
    color: $white;

    &:hover,
    &:focus {
        opacity: 0.7;
    }
}

.aside-block__close {
    @extend .vector-close;
}

.aside-block__total {
    align-items: center;
    background-color: rgba($white, 0.15);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-weight: bold;
    padding-bottom: rem-calc($grid-gutter-width-base / 2);
    padding-left: rem-calc($grid-gutter-width-base);
    padding-right: rem-calc($grid-gutter-width-base);
    padding-top: rem-calc($grid-gutter-width-base / 2);
}

.aside-block__total--subtotal {
    padding-bottom: 0;
}

.aside-block__total--tax {
    padding-bottom: 0;
    padding-top: 0;
}

.aside-block__subtotal__title,
.aside-block__tax__title,
.aside-block__total__title {
    flex: 1 0 50%;
    //scss-lint:disable ImportantRule
    font-size: rem-calc($font-size-base) !important;
    margin-bottom: 0;
}

.aside-block__tax__price,
.aside-block__subtotal__price {
    flex: 1 0 50%;
    //scss-lint:disable ImportantRule
    font-size: rem-calc(24px) !important;
    margin-bottom: 0;
    text-align: right;
}

.aside-block__total__price {
    flex: 1 0 50%;
    //scss-lint:disable ImportantRule
    font-size: rem-calc(36px) !important;
    margin-bottom: 0;
    text-align: right;
}

.aside-block__footer {
    background-color: rgba(0, 0, 0, 0.4);
    padding: rem-calc($grid-gutter-width-base) rem-calc(36px);
}

.aside-block__footer__button {
    @extend .button;
    @extend .button--primary;
    display: block;
    margin-left: auto;
    padding-left: 2rem;
    padding-right: 2rem;

    @include media-breakpoint-up(md) {
        padding-left: 4rem;
        padding-right: 4rem;
    }
}

.aside-block__footer__button-success {
    @extend .button;
    @extend .button--success;
    width: 100%;
}

.aside-block__email-estimate {
    color: $white;
    display: block;
    text-align: center;

    &:hover,
    &:focus {
        color: $white;
        opacity: 0.7;
        transition: $transition-base;
    }

    .aside-block__footer__button + & {
        margin-top: 1rem;
    }
}

.aside-block__email-estimate__vector {
    @extend .vector-envelope;
    color: currentColor;
    display: inline-block;
    height: rem-calc(14px);
    margin-right: 0.5rem;
    vertical-align: middle;
    width: rem-calc(18px);
}

.aside-block__email-estimate__title {
    color: currentColor;
    display: inline-block;
    font-size: rem-calc($font-size-sm);
    font-weight: bold;
    text-transform: uppercase;
    vertical-align: middle;
}

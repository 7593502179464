.hosting-plans {
    @extend %vertical-space;
}

.hosting-plans__aside {
    @extend .global__column;
    @extend .global__column--lg-3;
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
}

.hosting-plans__aside__title {
    color: $brand-primary;
    font-family: $font-family-sans-serif;
    font-size: rem-calc($font-size-base);
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
}

.hosting-plans__aside__title__extra {
    display: none;

    @include media-breakpoint-up(lg) {
        display: inline;
    }
}

.hosting-plans__aside__list {
    @extend %reset-list;
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
    }
}

.hosting-plans__select {
    display: block;

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.hosting-plans__aside__link {
    color: $body-color;

    .hosting-plans__aside__item.is-active & {
        font-weight: bold;

        &:hover {
            color: currentColor;
        }
    }
}

.hosting-plans__content {
    @extend .global__column;
    @extend .global__column--lg-9;
}

.hosting-plans__list {
    @extend %reset-list;
}

.hosting-plans__item {
    display: none;

    &.is-active {
        display: block;
    }
}

.hosting-plans__header {
    @extend %row;
    align-items: center;
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
        margin-bottom: 4rem;
    }
}

.hosting-plans__header__text {
    @extend .global__column;
    @extend .global__column--sm-8;
}

.hosting-plans__header__title {
    margin-bottom: rem-calc(21px);
}

.hosting-plans__header__description {
    margin-bottom: 1rem;
}

.hosting-plans__pricing {
    @extend .global__column;
    @extend .global__column--sm-4;
}

.hosting-plans__pricing__header {
    background-color: $gray-lightest;
    margin-bottom: 1rem;
    padding: 1rem 2rem;
    text-align: center;
}

.hosting-plans__pricing__caption {
    @include clearfix;
    line-height: (18 / 16);
    margin-bottom: 2rem;
}

.hosting-plans__pricing__price {
    @include clearfix;
    color: $black;
    display: inline-table;
    line-height: 0;
    margin-bottom: 0.5rem;
    vertical-align: top;

    sup {
        display: table-cell;
        font-size: $font-size-lg;
        font-weight: $font-weight-semibold;
        top: 0;
        vertical-align: top;

        &:first-of-type {
            font-size: rem-calc(28px);
        }
    }

    span {
        display: table-cell;
        line-height: 0;
        vertical-align: bottom;

        &:first-of-type {
            font-size: rem-calc(48px);
            font-weight: $font-weight-semibold;

            @include media-breakpoint-up(lg) {
                font-size: rem-calc(70px);
            }
        }
    }
}

.hosting-plans__pricing__per {
    color: $black;
    font-size: rem-calc($font-size-xs);
    margin: 0;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
        margin-top: 1rem;
    }
}

.hosting-plans__pricing__button {
    @extend .button;
    @extend .button--info;
    width: 100%;

    &:hover,
    &:focus {
        color: $white;
    }
}

.hosting-plans__pricing__button--red {
    @extend .button--danger;

    &:hover,
    &:focus {
        background-color: darken($brand-danger, 15%);
        border-color: darken($brand-danger, 15%);
        color: $white;
    }
}

.hosting-plans__features__title {
    margin-bottom: rem-calc(46px);
}

.hosting-plans__features__list {
    @extend %reset-list;
    @extend %row;
}

.hosting-plans__feature {
    @extend .global__column;
    @extend .global__column--sm-6;
    @extend .global__column--md-4;
    @include clearfix;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1rem;
    position: relative;

    @include media-breakpoint-up(md) {
        margin-bottom: 2rem;
    }
}

.hosting-plans__feature__vector-container {
    flex: 0 1 auto;
    height: rem-calc(55px);
    margin-right: rem-calc(25px);
    width: rem-calc(55px);
}

.hosting-plans__feature__vector {
    float: right;
}

.hosting-plans__feature__vector--outlined-control-panel {
    @extend .vector-outlined-control-panel;
}

.hosting-plans__feature__vector--outlined-servers {
    @extend .vector-outlined-servers;
}

.hosting-plans__feature__vector--outlined-shield {
    @extend .vector-outlined-shield;
}

.hosting-plans__feature__vector--outlined-headset {
    @extend .vector-outlined-headset;
}

.hosting-plans__feature__vector--outlined-speed {
    @extend .vector-outlined-speed;
}

.hosting-plans__feature__vector--outlined-databases {
    @extend .vector-outlined-databases;
}

.hosting-plans__feature__vector--outlined-uptime {
    @extend .vector-outlined-uptime;
}

.hosting-plans__feature__vector--outlined-one-click-install {
    @extend .vector-outlined-one-click-install;
}

.hosting-plans__feature__vector--outlined-australia {
    @extend .vector-outlined-australia;
}

.hosting-plans__feature__text {
    flex: 1 0 auto;
    position: relative;
    top: rem-calc(-7px); // correct font x-height
    width: rem-calc(50px);
}

.hosting-plans__feature__title {
    font-size: rem-calc(20px);
    font-weight: $font-weight-semibold;
    margin: 0;
    text-align: left;
    white-space: nowrap;
}

.hosting-plans__feature__caption {
    font-size: rem-calc($font-size-sm);
    margin: 0;
    text-align: left;
    // Commented out by Aaron, but will require a Joel fix.
    // white-space: nowrap;
}

.footer {
    @extend %font-smoothing-auto;
    @include clearfix;
    background-color: $black;
    color: $white;
    font-size: rem-calc($font-size-sm);

    a {
        color: $gray;

        &:hover,
        &:focus {
            color: darken($gray, 15%);
        }
    }

    label {
        @extend %font-smoothing-antialiased;
    }
}

.footer__secondary {
    padding: 0.5rem 0 0;
}

.footer__secondary__content {
    @extend %vertical-space--sm;
    border-top: 2px solid $gray;
    color: $gray;
    font-size: rem-calc($font-size-xs);
}

.footer__secondary__row {
    @extend %row;
}

.footer__copyright,
.footer__socials {
    @extend .global__column;

    @include media-breakpoint-up(sm) {
        height: rem-calc(24px);
    }
}

.footer__copyright {
    @extend .global__column--sm-9;
    @extend %font-smoothing-auto;
    margin-bottom: 0;

    p {
        text-align: center;

        @include media-breakpoint-up(sm) {
            margin-bottom: 0;
            text-align: left;
        }
    }
}

.footer__socials {
    @extend .global__column--sm-3;
    line-height: 0;
}

.footer__navigation {
    @extend .global__column;
    @extend .global__column--lg-9;
    margin-bottom: 3rem;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
    }
}

.footer__navigation__column-list {
    @extend %reset-list;
    @extend %row;
    justify-content: center;
}

.footer__navigation__column {
    @extend .global__column;
    @extend .global__column--sm-4;
    margin-bottom: 2rem;
    text-align: center;

    @include media-breakpoint-up(sm) {
        margin-bottom: 0;
        text-align: left;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.footer__navigation__title {
    @extend %font-smoothing-antialiased;
    color: $white;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
        margin-bottom: 1.5rem;
    }
}

.footer__navigation__list {
    @extend %reset-list;
}

.footer__navigation__item {
    margin-bottom: 0.25rem;

    @include media-breakpoint-up(lg) {
        margin-bottom: 1rem;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.footer__details {
    @extend .global__column;
    @extend .global__column--lg-3;
    text-align: center;

    @include media-breakpoint-down(md) {
        margin-left: auto;
        margin-right: auto;
        max-width: rem-calc(343px);
    }

    @include media-breakpoint-up(lg) {
        text-align: left;
    }
}

.footer__socials__list {
    @extend %reset-list;
    line-height: inherit;
    text-align: center;

    @include media-breakpoint-up(sm) {
        text-align: right;
    }
}

.footer__socials__item {
    display: inline-block;
    height: rem-calc(24px);
    margin: 0 0.25rem;
    width: rem-calc(24px);

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
}

.footer__socials__title {
    @extend %sr-only;
}

.footer__socials__link {
    display: block;
}

.footer__socials__vector {
    opacity: 0.4;
    transition: $transition-base;

    &:hover,
    &:focus {
        opacity: 1;
    }
}

.footer__socials__vector--facebook {
    @extend .vector-facebook;
}

.footer__socials__vector--twitter {
    @extend .vector-twitter;
}

.footer__socials__vector--linkedin {
    @extend .vector-linkedin;
}

.footer__address {
    @include clearfix;

    color: $gray;
    display: inline-block;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    max-width: rem-calc(200px);

    @include media-breakpoint-up(lg) {
        margin: 0 0 1.5rem;
        max-width: none;
    }
}

.footer__address__title {
    @extend %font-smoothing-antialiased;
    clear: left;
    float: left;
    font-weight: $font-weight-semibold;
    margin-right: 0.5rem;
}

.footer__address__details {
    clear: right;
    float: left;
}

.footer__logo__vector {
    @extend .vector-hostking;
}
